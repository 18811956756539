import { useEffect, useState } from "react";

interface Reward {
  accumulated: string;
  decimals: number;
  symbol: string;
  unclaimed: string;
  reasons: {
    [key: string]: {
      accumulated: string;
      unclaimed: string;
    }
  };
}

interface RewardsResponse {
  [key: string]: Reward;
}

interface OutputReward extends Reward {
  id: string;
}

function transformData(data: { [key: string]: Reward }): OutputReward[] {
  return Object.entries(data).map(([key, value]) => ({
    id: key,
    accumulated: value.accumulated,
    decimals: value.decimals,
    symbol: value.symbol,
    unclaimed: value.unclaimed,
    reasons: value.reasons,
  }));
}


export const useFetchUsersAllRewards = (chainId?: number, user?: string) => {
  const [data, setData] = useState<OutputReward[] | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!chainId || !user) return;
    const fetchData = async () => {
      try {
        setLoading(true);
        const url = `https://api.merkl.xyz/v3/userRewards?chainId=${chainId}&user=${user}`;
        const response = await fetch(url, {
          mode: 'cors',
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const json = await response.json();
        console.log('userData json', json, response, url)
        setData(transformData(json));
        setLoading(false);
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("An unknown error occurred");
        }
        setLoading(false);
      }
    };

    fetchData();
  }, [chainId, user]);

  return { data, error, loading };
};


export const useFetchUserRewards = (chainId?: number, mainParameter?: string, user?: string) => {
  const [data, setData] = useState<RewardsResponse | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!chainId || !mainParameter || !user) return;
    const fetchData = async () => {
      try {
        setLoading(true);
        const url = `https://api.merkl.xyz/v3/userRewards?chainId=${chainId}&mainParameter=${mainParameter}&user=${user}`;
        const response = await fetch(url, {
          mode: 'cors',
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const json = await response.json();
        console.log('userData json', json, response, url)
        setData(json);
        setLoading(false);
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("An unknown error occurred");
        }
        setLoading(false);
      }
    };

    fetchData();
  }, [chainId, mainParameter, user]);

  return { data, error, loading };
};

