import { Trans } from '@lingui/macro'
import { CurrencyAmount, Fraction, Token } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import RangeBadge from 'components/Badge/RangeBadge'
import { ButtonGray, SmallButtonPrimary } from 'components/Button'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import { FeeTierText, PrimaryPositionIdData } from 'components/PositionListItem'
import { calculatePositionWidth, percentageToMultiplier } from 'components/PresetSelector'
import { RowBetween } from 'components/Row'
import { formatUnits, parseUnits } from 'ethers/lib/utils'
import useStablecoinPrice from 'hooks/useStablecoinPrice'
import { SteerPosition, SteerPositionWithCurrency } from 'hooks/useSteerData'
import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { currencyId } from 'utils/currencyId'
import { unwrappedToken } from 'utils/unwrappedToken'

const AccordionRow = styled.div`
  align-items: center;
  display: flex;
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${({ theme }) => theme.textPrimary};
  padding: 16px;
  text-decoration: none;
  font-weight: 500;

  & > div:not(:first-child) {
    text-align: center;
  }
  :hover {
    background-color: ${({ theme }) => theme.hoverDefault};
  }

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    flex-direction: column;
    row-gap: 8px;
  `};
`

const Wrapper = styled.div<{ show?: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  width: 100%;
`

const ActionButtonRow = styled(RowBetween)`
  width: 100%;
  flex-direction: row;
  * {
    width: 100%;
  }
`

const ButtonVisibleGray = styled(ButtonGray)`
  background-color: ${({ theme }) => theme.backgroundInteractive};
`

export default function SteerLPItem({ position }: { position: SteerPosition }) {
  const { chainId } = useWeb3React()
  const [expanded, setExpanded] = useState(false)

  const minTick = Number(position.lowerTick ?? 0)
  const maxTick = Number(position.upperTick ?? 0)
  const currentTick = Number(position.tick ?? 0)

  // check if price is within range
  const outOfRange: boolean =
    position && position.lowerTick && position.upperTick
      ? Number(position.tick) < Number(position.lowerTick) || Number(position.tick) >= Number(position.upperTick)
      : false

  const positionWidthPercent = calculatePositionWidth(currentTick, minTick, maxTick)
  const strategy = position.strategyName.toLowerCase().includes('stable')
    ? 'Stable'
    : percentageToMultiplier(positionWidthPercent) > 1.2
    ? 'Wide'
    : 'Narrow'
  const currency0 = useMemo(
    () =>
      chainId && position.token0Address && position.token0Decimals
        ? new Token(
            chainId,
            position.token0Address,
            position.token0Decimals,
            position.token0Symbol,
            position.token0Name,
            true
          )
        : undefined,
    [chainId, position.token0Address, position.token0Decimals, position.token0Symbol, position.token0Name]
  )

  const currency1 = useMemo(
    () =>
      chainId && position.token1Address && position.token1Decimals
        ? new Token(
            chainId,
            position.token1Address,
            position.token1Decimals,
            position.token1Symbol,
            position.token1Name,
            true
          )
        : undefined,
    [chainId, position.token1Address, position.token1Decimals, position.token1Symbol, position.token1Name]
  )

  const positionWithCurrency = useMemo(
    () => ({
      ...position,
      currency0,
      currency1,
    }),
    [currency0, currency1, position]
  )

  return (
    <AccordionRow onClick={() => setExpanded(!expanded)}>
      <RowBetween>
        <PrimaryPositionIdData>
          <DoubleCurrencyLogo currency0={currency0} currency1={currency1} size={18} margin />
          <ThemedText.SubHeader>
            &nbsp;{currency1?.symbol}&nbsp;/&nbsp;{currency0?.symbol}
          </ThemedText.SubHeader>
          <FeeTierText>{strategy}</FeeTierText>
        </PrimaryPositionIdData>
        <RangeBadge removed={false} inRange={!outOfRange} />
      </RowBetween>
      <SteerLPItemDetails position={positionWithCurrency} show={expanded} />
    </AccordionRow>
  )
}

function SteerLPItemDetails({ position, show }: { position: SteerPositionWithCurrency; show: boolean }) {
  const steerToken0VaultBalance = position.token0Balance
    ? Number(formatUnits(position.token0Balance, position.token0Decimals))
    : 0
  const steerToken1VaultBalance = position.token1Balance
    ? Number(formatUnits(position.token1Balance, position.token1Decimals))
    : 0

  const vaultTotalSupply = Number(position.totalSupply ?? 0)
  const vaultBalance = position.depositedBalance
  const stakedAmount = position.stakedAmount ?? 0

  const vaultTotalSupplyNum = vaultTotalSupply

  const vaultBalanceNum = Number(vaultBalance?.toExact() ?? '0') + stakedAmount

  const token0Balance = useMemo(() => {
    if (vaultTotalSupplyNum > 0) {
      return (vaultBalanceNum * steerToken0VaultBalance) / vaultTotalSupplyNum
    }
    return 0
  }, [vaultTotalSupplyNum, vaultBalanceNum, steerToken0VaultBalance])

  const token1Balance = useMemo(() => {
    if (vaultTotalSupplyNum > 0) {
      return (vaultBalanceNum * steerToken1VaultBalance) / vaultTotalSupplyNum
    }
    return 0
  }, [vaultTotalSupplyNum, vaultBalanceNum, steerToken1VaultBalance])

  const price0 = useStablecoinPrice(position.currency0 ?? undefined)
  const price1 = useStablecoinPrice(position.currency1 ?? undefined)

  const fiatValueOfLiquidity: CurrencyAmount<Token> | null = useMemo(() => {
    if (!price0 || !price1 || !position || !position.currency0 || !position.currency1) return null
    const amount0 = price0.quote(
      CurrencyAmount.fromRawAmount(
        position.currency0,
        parseUnits(token0Balance.toFixed(position.token0Decimals), position.token0Decimals).toString()
      )
    )
    const amount1 = price1.quote(
      CurrencyAmount.fromRawAmount(
        position.currency1,
        parseUnits(token1Balance.toFixed(position.token1Decimals), position.token1Decimals).toString()
      )
    )
    return amount0.add(amount1)
  }, [price0, price1, position, token0Balance, token1Balance])

  return (
    <Wrapper show={show}>
      <RowBetween mt={2}>
        <ThemedText.SubHeaderSmall>
          <Trans>My Liquidity</Trans>
        </ThemedText.SubHeaderSmall>
        <ThemedText.SubHeaderSmall>
          $
          {fiatValueOfLiquidity?.greaterThan(new Fraction(1, 100))
            ? fiatValueOfLiquidity.toFixed(2, { groupSeparator: ',' })
            : '-'}
        </ThemedText.SubHeaderSmall>
      </RowBetween>
      <RowBetween mt={2}>
        <PrimaryPositionIdData>
          <CurrencyLogo currency={position.currency1} size="24px" />
          <ThemedText.SubHeaderSmall>
            <Trans>Pooled</Trans> {position.currency1?.symbol}
          </ThemedText.SubHeaderSmall>
        </PrimaryPositionIdData>
        <ThemedText.SubHeaderSmall>{formatNumber(token1Balance)}</ThemedText.SubHeaderSmall>
      </RowBetween>
      <RowBetween mt={1}>
        <PrimaryPositionIdData>
          <CurrencyLogo currency={position.currency0} size="24px" />
          <ThemedText.SubHeaderSmall>
            <Trans>Pooled</Trans> {position.currency0?.symbol}
          </ThemedText.SubHeaderSmall>
        </PrimaryPositionIdData>
        <ThemedText.SubHeaderSmall>{formatNumber(token0Balance)}</ThemedText.SubHeaderSmall>
      </RowBetween>
      <ActionButtonRow mt={2}>
        {position?.currency0 && position?.currency1 && position?.feeTier ? (
          <SmallButtonPrimary
            as={Link}
            to={`/increase/steer/${currencyId(unwrappedToken(position.currency0))}/${currencyId(
              unwrappedToken(position.currency1)
            )}/${position.feeTier}/${position.address}`}
            padding="6px 8px"
            width="fit-content"
            $borderRadius="12px"
            style={{ marginRight: '8px' }}
          >
            <Trans>Increase Liquidity</Trans>
          </SmallButtonPrimary>
        ) : null}
        <ButtonVisibleGray
          as={Link}
          to={`/remove/steer/${position.address}`}
          padding="6px 8px"
          width="fit-content"
          $borderRadius="12px"
        >
          <Trans>Remove Liquidity</Trans>
        </ButtonVisibleGray>
      </ActionButtonRow>
    </Wrapper>
  )
}

function formatNumber(unformatted: number | string | undefined, showDigits = 2) {
  // get fraction digits for small number
  if (!unformatted) return 0
  const absNumber = Math.abs(Number(unformatted))
  if (absNumber > 0) {
    const digits = Math.ceil(Math.log10(1 / absNumber))
    if (digits < 3) {
      return Number(unformatted).toLocaleString('us')
    } else {
      return Number(unformatted).toFixed(digits + showDigits)
    }
  } else {
    return 0
  }
}
