import { Currency, CurrencyAmount } from '@uniswap/sdk-core'
import { SupportedChainId } from 'constants/chains'
import JSBI from 'jsbi'

/**
 * Given some token amount, return the max that can be spent of it
 * @param currencyAmount to return max of
 */
export function maxAmountSpend(
  currencyAmount?: CurrencyAmount<Currency>,
  chainId?: number
): CurrencyAmount<Currency> | undefined {
  if (!currencyAmount) return undefined
  if (currencyAmount.currency.isNative) {
    if (JSBI.greaterThan(currencyAmount.quotient, MIN_NATIVE_CURRENCY_FOR_GAS[chainId ?? 592])) {
      return CurrencyAmount.fromRawAmount(
        currencyAmount.currency,
        JSBI.subtract(currencyAmount.quotient, MIN_NATIVE_CURRENCY_FOR_GAS[chainId ?? 592])
      )
    } else {
      return CurrencyAmount.fromRawAmount(currencyAmount.currency, JSBI.BigInt(0))
    }
  }
  return currencyAmount
}

const MIN_NATIVE_CURRENCY_FOR_GAS: {
  [chainId: number]: JSBI
} = {
  [SupportedChainId.ASTR]: JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(17)), // .1 ASTR
  [SupportedChainId.SHIBUYA]: JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(17)),
  [SupportedChainId.ZKATANA]: JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(14)),
  [SupportedChainId.ZKEVM]: JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(14)),
}
