import { FACTORY_ADDRESS as V2_FACTORY_ADDRESS, INIT_CODE_HASH as V2_INIT_CODE_HASH } from '@arth-s/v2-sdk'
import { FACTORY_ADDRESS as V3_FACTORY_ADDRESS } from '@uniswap/v3-sdk'
import { SupportedChainId } from 'constants/chains'

import { constructSameAddressMap } from '../utils/constructSameAddressMap'

type AddressMap = { [chainId: number]: string }

export const UNI_ADDRESS: AddressMap = constructSameAddressMap('0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984')

export const UNISWAP_NFT_AIRDROP_CLAIM_ADDRESS = '0x8B799381ac40b838BBA4131ffB26197C432AFe78'

export const V2_FACTORY_ADDRESSES: AddressMap = {
  ...constructSameAddressMap(V2_FACTORY_ADDRESS),
  [SupportedChainId.ASTR]: '0xA9473608514457b4bF083f9045fA63ae5810A03E',
  [SupportedChainId.SHIBUYA]: '0x959b56586531b7a74702721908253DB40f983e1B',
}

export const V2_INIT_CODE_HASHES: AddressMap = {
  ...constructSameAddressMap(V2_INIT_CODE_HASH),
  [SupportedChainId.ASTR]: '0x613b36de6401276e4d938ad0db4063490e66bb3ab2e4aec17cab78a15ea7a0b6',
  [SupportedChainId.SHIBUYA]: '0xdb47b8e75ce8dbb4867c4ea80041e45f1715aa1d3c7edb029bdad0f920b1841f',
}
export const V2_ROUTER_ADDRESS: AddressMap = {
  ...constructSameAddressMap('0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D'),
  [SupportedChainId.ASTR]: '0xE915D2393a08a00c5A463053edD31bAe2199b9e7',
  [SupportedChainId.SHIBUYA]: '0xd4e28326f9F1994eC74eFcA2057c74D96303437a',
}

export const V3_ROUTER_ADDRESS: AddressMap = {
  [SupportedChainId.ZKATANA]: '0xA17E7Ba271dC2CC12BA5ECf6D178bF818A6D76EB',
  [SupportedChainId.ZKEVM]: '0x568C3aC2179Fa3D1BF0BA58788354BBE7C40CCF8',
}

// celo v3 addresses
const CELO_V3_CORE_FACTORY_ADDRESSES = '0xAfE208a311B21f13EF87E33A90049fC17A7acDEc'
const CELO_V3_MIGRATOR_ADDRESSES = '0x3cFd4d48EDfDCC53D3f173F596f621064614C582'
const CELO_MULTICALL_ADDRESS = '0x633987602DE5C4F337e3DbF265303A1080324204'
const CELO_QUOTER_ADDRESSES = '0x82825d0554fA07f7FC52Ab63c961F330fdEFa8E8'
const CELO_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES = '0x3d79EdAaBC0EaB6F08ED885C05Fc0B014290D95A'
const CELO_TICK_LENS_ADDRESSES = '0x5f115D9113F88e0a0Db1b5033D90D4a9690AcD3D'

// BNB v3 addresses
const BNB_V3_CORE_FACTORY_ADDRESSES = '0xdB1d10011AD0Ff90774D0C6Bb92e5C5c8b4461F7'
const BNB_V3_MIGRATOR_ADDRESSES = '0x32681814957e0C13117ddc0c2aba232b5c9e760f'
const BNB_MULTICALL_ADDRESS = '0x963Df249eD09c358A4819E39d9Cd5736c3087184'
const BNB_QUOTER_ADDRESSES = '0x78D78E420Da98ad378D7799bE8f4AF69033EB077'
const BNB_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES = '0x7b8A01B39D58278b5DE7e48c8449c9f4F5170613'
const BNB_TICK_LENS_ADDRESSES = '0xD9270014D396281579760619CCf4c3af0501A47C'

// optimism goerli addresses
const OPTIMISM_GOERLI_V3_CORE_FACTORY_ADDRESSES = '0xB656dA17129e7EB733A557f4EBc57B76CFbB5d10'
const OPTIMISM_GOERLI_V3_MIGRATOR_ADDRESSES = '0xf6c55fBe84B1C8c3283533c53F51bC32F5C7Aba8'
const OPTIMISM_GOERLI_MULTICALL_ADDRESS = '0x07F2D8a2a02251B62af965f22fC4744A5f96BCCd'
const OPTIMISM_GOERLI_QUOTER_ADDRESSES = '0x9569CbA925c8ca2248772A9A4976A516743A246F'
const OPTIMISM_GOERLI_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES = '0x39Ca85Af2F383190cBf7d7c41ED9202D27426EF6'
const OPTIMISM_GOERLI_TICK_LENS_ADDRESSES = '0xe6140Bd164b63E8BfCfc40D5dF952f83e171758e'

// arbitrum goerli v3 addresses
const ARBITRUM_GOERLI_V3_CORE_FACTORY_ADDRESSES = '0x4893376342d5D7b3e31d4184c08b265e5aB2A3f6'
const ARBITRUM_GOERLI_V3_MIGRATOR_ADDRESSES = '0xA815919D2584Ac3F76ea9CB62E6Fd40a43BCe0C3'
const ARBITRUM_GOERLI_MULTICALL_ADDRESS = '0x8260CB40247290317a4c062F3542622367F206Ee'
const ARBITRUM_GOERLI_QUOTER_ADDRESSES = '0x1dd92b83591781D0C6d98d07391eea4b9a6008FA'
const ARBITRUM_GOERLI_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES = '0x622e4726a167799826d1E1D150b076A7725f5D81'
const ARBITRUM_GOERLI_TICK_LENS_ADDRESSES = '0xb52429333da969a0C79a60930a4Bf0020E5D1DE8'

// astar v3 addresses
const ASTAR_V3_CORE_FACTORY_ADDRESSES = '0x69E92b56e4BF4C0FFa2cFB087c7EA47E846a7244'
const ASTAR_V3_MIGRATOR_ADDRESSES = '0x6b069294FDd43681e78FE3B22B3d07f9B15dBf6d'
const ASTAR_MULTICALL_ADDRESS = '0x5A7bb446E2f6AdD7419ca2CC6eCED8e0827346B6'
const ASTAR_QUOTER_ADDRESSES = '0xAFb98ab0394C32dA8E42039ea276E50CBE0144b7'
const ASTAR_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES = '0x9EF2a830C17DA510Cbb90b793b2AD345634d0Ea0'
const ASTAR_TICK_LENS_ADDRESSES = '0xf369b9904050CD906CFB088A36b5FBA59CA1F698'

// astar testnet v3 addresses
const ASTAR_SHIBUYA_V3_CORE_FACTORY_ADDRESSES = '0x75E413541C977403a2ac408dd88Ee50228aC9637'
const ASTAR_SHIBUYA_V3_MIGRATOR_ADDRESSES = '0xa9701772C7579e6E7EFB9bEBc4925bD03B52BED7'
const ASTAR_SHIBUYA_MULTICALL_ADDRESS = '0x08173F314EFe04E7f8cAcF99E0998B67bC642f83'
const ASTAR_SHIBUYA_QUOTER_ADDRESSES = '0xf65A44904c9c22653F17eBd16F6a2271A87B9D2A'
const ASTAR_SHIBUYA_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES = '0xE89aa894030218af63506f50e672616af5B05893'
const ASTAR_SHIBUYA_TICK_LENS_ADDRESSES = '0x6eAcD2f0382987a28C7842bffcfb5d4fA9BED50f'

const ASTAR_ZKATANA_V3_CORE_FACTORY_ADDRESSES = '0xAeaaf0e2c81Af264101B9129C00F4440cCF0F720'
const ASTAR_ZKATANA_MULTICALL_ADDRESS = '0x6E85A2497e506936cdd0b058B408d8Db4851F67B'
const ASTAR_ZKATANA_QUOTER_ADDRESSES = '0xae1989Ce71bCACD02AE1a5569ECD296E7D74a21C'
const ASTAR_ZKATANA_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES = '0xE17D2c5c7761092f31c9Eca49db426D5f2699BF0'
const ASTAR_ZKATANA_TICK_LENS_ADDRESSES = '0x28674A44198BCd674E21D76C16a948B97B0482a2'

const ASTAR_ZKEVM_V3_CORE_FACTORY_ADDRESSES = '0xAeaaf0e2c81Af264101B9129C00F4440cCF0F720'
const ASTAR_ZKEVM_MULTICALL_ADDRESS = '0x6E85A2497e506936cdd0b058B408d8Db4851F67B'
const ASTAR_ZKEVM_QUOTER_ADDRESSES = '0x938BCe03532A3845Ec0aB75069E3d84f9cd2826f'
const ASTAR_ZKEVM_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES = '0xA73Ae19C02A5fB7A6FBCC50F3A1AcbdA362Ee431'
const ASTAR_ZKEVM_TICK_LENS_ADDRESSES = '0x28674A44198BCd674E21D76C16a948B97B0482a2'

/* V3 Contract Addresses */
export const V3_CORE_FACTORY_ADDRESSES: AddressMap = {
  ...constructSameAddressMap(V3_FACTORY_ADDRESS, [
    SupportedChainId.OPTIMISM,
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ]),
  [SupportedChainId.CELO]: CELO_V3_CORE_FACTORY_ADDRESSES,
  [SupportedChainId.CELO_ALFAJORES]: CELO_V3_CORE_FACTORY_ADDRESSES,
  [SupportedChainId.BNB]: BNB_V3_CORE_FACTORY_ADDRESSES,
  [SupportedChainId.OPTIMISM_GOERLI]: OPTIMISM_GOERLI_V3_CORE_FACTORY_ADDRESSES,
  [SupportedChainId.ARBITRUM_GOERLI]: ARBITRUM_GOERLI_V3_CORE_FACTORY_ADDRESSES,
  [SupportedChainId.ASTR]: ASTAR_V3_CORE_FACTORY_ADDRESSES,
  [SupportedChainId.SHIBUYA]: ASTAR_SHIBUYA_V3_CORE_FACTORY_ADDRESSES,
  [SupportedChainId.ZKATANA]: ASTAR_ZKATANA_V3_CORE_FACTORY_ADDRESSES,
  [SupportedChainId.ZKEVM]: ASTAR_ZKEVM_V3_CORE_FACTORY_ADDRESSES,
}

export const V3_MIGRATOR_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0xA5644E29708357803b5A882D272c41cC0dF92B34', [
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ]),
  [SupportedChainId.CELO]: CELO_V3_MIGRATOR_ADDRESSES,
  [SupportedChainId.CELO_ALFAJORES]: CELO_V3_MIGRATOR_ADDRESSES,
  [SupportedChainId.BNB]: BNB_V3_MIGRATOR_ADDRESSES,
  [SupportedChainId.OPTIMISM_GOERLI]: OPTIMISM_GOERLI_V3_MIGRATOR_ADDRESSES,
  [SupportedChainId.ARBITRUM_GOERLI]: ARBITRUM_GOERLI_V3_MIGRATOR_ADDRESSES,
  [SupportedChainId.ASTR]: ASTAR_V3_MIGRATOR_ADDRESSES,
  [SupportedChainId.SHIBUYA]: ASTAR_SHIBUYA_V3_MIGRATOR_ADDRESSES,
}

export const MULTICALL_ADDRESS: AddressMap = {
  ...constructSameAddressMap('0x1F98415757620B543A52E61c46B32eB19261F984', [
    SupportedChainId.OPTIMISM,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ]),
  [SupportedChainId.ARBITRUM_ONE]: '0xadF885960B47eA2CD9B55E6DAc6B42b7Cb2806dB',
  [SupportedChainId.CELO]: CELO_MULTICALL_ADDRESS,
  [SupportedChainId.CELO_ALFAJORES]: CELO_MULTICALL_ADDRESS,
  [SupportedChainId.BNB]: BNB_MULTICALL_ADDRESS,
  [SupportedChainId.OPTIMISM_GOERLI]: OPTIMISM_GOERLI_MULTICALL_ADDRESS,
  [SupportedChainId.ARBITRUM_GOERLI]: ARBITRUM_GOERLI_MULTICALL_ADDRESS,
  [SupportedChainId.ASTR]: ASTAR_MULTICALL_ADDRESS,
  [SupportedChainId.SHIBUYA]: ASTAR_SHIBUYA_MULTICALL_ADDRESS,
  [SupportedChainId.ZKATANA]: ASTAR_ZKATANA_MULTICALL_ADDRESS,
  [SupportedChainId.ZKEVM]: ASTAR_ZKEVM_MULTICALL_ADDRESS,
}

/**
 * The oldest V0 governance address
 */
export const GOVERNANCE_ALPHA_V0_ADDRESSES: AddressMap = constructSameAddressMap(
  '0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F'
)
/**
 * The older V1 governance address
 */
export const GOVERNANCE_ALPHA_V1_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0xC4e172459f1E7939D522503B81AFAaC1014CE6F6',
}
/**
 * The latest governor bravo that is currently admin of timelock
 */
export const GOVERNANCE_BRAVO_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x408ED6354d4973f66138C91495F2f2FCbd8724C3',
}

export const TIMELOCK_ADDRESS: AddressMap = {
  ...constructSameAddressMap('0x1a9C8182C09F50C8318d769245beA52c32BE35BC'),
}

export const MERKLE_DISTRIBUTOR_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x090D4613473dEE047c3f2706764f49E0821D256e',
}

export const ARGENT_WALLET_DETECTOR_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0xeca4B0bDBf7c55E9b7925919d03CbF8Dc82537E8',
}

export const QUOTER_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6', [
    SupportedChainId.OPTIMISM,
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ]),
  [SupportedChainId.CELO]: CELO_QUOTER_ADDRESSES,
  [SupportedChainId.CELO_ALFAJORES]: CELO_QUOTER_ADDRESSES,
  [SupportedChainId.BNB]: BNB_QUOTER_ADDRESSES,
  [SupportedChainId.OPTIMISM_GOERLI]: OPTIMISM_GOERLI_QUOTER_ADDRESSES,
  [SupportedChainId.ARBITRUM_GOERLI]: ARBITRUM_GOERLI_QUOTER_ADDRESSES,
  [SupportedChainId.ASTR]: ASTAR_QUOTER_ADDRESSES,
  [SupportedChainId.SHIBUYA]: ASTAR_SHIBUYA_QUOTER_ADDRESSES,
  [SupportedChainId.ZKATANA]: ASTAR_ZKATANA_QUOTER_ADDRESSES,
  [SupportedChainId.ZKEVM]: ASTAR_ZKEVM_QUOTER_ADDRESSES,
}

export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0xC36442b4a4522E871399CD717aBDD847Ab11FE88', [
    SupportedChainId.OPTIMISM,
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ]),
  [SupportedChainId.CELO]: CELO_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  [SupportedChainId.CELO_ALFAJORES]: CELO_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  [SupportedChainId.BNB]: BNB_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  [SupportedChainId.OPTIMISM_GOERLI]: OPTIMISM_GOERLI_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  [SupportedChainId.ARBITRUM_GOERLI]: ARBITRUM_GOERLI_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  [SupportedChainId.ASTR]: ASTAR_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  [SupportedChainId.SHIBUYA]: ASTAR_SHIBUYA_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  [SupportedChainId.ZKATANA]: ASTAR_ZKATANA_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  [SupportedChainId.ZKEVM]: ASTAR_ZKEVM_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
}

export const ENS_REGISTRAR_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  [SupportedChainId.GOERLI]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
}

export const SOCKS_CONTROLLER_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x65770b5283117639760beA3F867b69b3697a91dd',
}

export const TICK_LENS_ADDRESSES: AddressMap = {
  [SupportedChainId.ARBITRUM_ONE]: '0xbfd8137f7d1516D3ea5cA83523914859ec47F573',
  [SupportedChainId.ARBITRUM_GOERLI]: ARBITRUM_GOERLI_TICK_LENS_ADDRESSES,
  [SupportedChainId.CELO]: CELO_TICK_LENS_ADDRESSES,
  [SupportedChainId.CELO_ALFAJORES]: CELO_TICK_LENS_ADDRESSES,
  [SupportedChainId.BNB]: BNB_TICK_LENS_ADDRESSES,
  [SupportedChainId.OPTIMISM_GOERLI]: OPTIMISM_GOERLI_TICK_LENS_ADDRESSES,
  [SupportedChainId.ASTR]: ASTAR_TICK_LENS_ADDRESSES,
  [SupportedChainId.SHIBUYA]: ASTAR_SHIBUYA_TICK_LENS_ADDRESSES,
  [SupportedChainId.ZKATANA]: ASTAR_ZKATANA_TICK_LENS_ADDRESSES,
  [SupportedChainId.ZKEVM]: ASTAR_ZKEVM_TICK_LENS_ADDRESSES,
}

export const POOL_INIT_CODE_HASHES: AddressMap = {
  [SupportedChainId.ASTR]: '0xcbcb4377b49b91fa6c071bf13c7ab5f79a6173e890a2f071ceb7ed884d68a237',
  [SupportedChainId.SHIBUYA]: '0xcbcb4377b49b91fa6c071bf13c7ab5f79a6173e890a2f071ceb7ed884d68a237',
  [SupportedChainId.ZKATANA]: '0xcbcb4377b49b91fa6c071bf13c7ab5f79a6173e890a2f071ceb7ed884d68a237',
  [SupportedChainId.ZKEVM]: '0xcbcb4377b49b91fa6c071bf13c7ab5f79a6173e890a2f071ceb7ed884d68a237',
}

export const STEER_VAULT_REGISTRY: AddressMap = {
  [SupportedChainId.ASTR]: '0x116Dba5DcE9CcDA828218b7eB46406810632014C',
  [SupportedChainId.ZKEVM]: '0xa1Dd21fbd9e1F0BF28d41F18bDC22326e50C02e9',
}

export const STEER_PERIPHERY: AddressMap = {
  [SupportedChainId.ASTR]: '0x1afD31627170607657224eD4aE701470209C4B2e',
  [SupportedChainId.ZKEVM]: '0x37Cff062D52Dd6E9E39Df619CCd30c037a36bB83',
}
