import { abi as IArthSwapV3PoolStateABI } from '@arth-s/v3-core/artifacts/contracts/interfaces/pool/IArthSwapV3PoolState.sol/IArthSwapV3PoolState.json'
import { CurrencyAmount, Fraction } from '@uniswap/sdk-core'
import { Token } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import STEER_STAKING_ABI from 'abis/steer-staking.json'
import SteerVaultABI from 'abis/steer-vault.json'
import { isSteerChain } from 'constants/chains'
import { formatUnits, Interface, parseUnits } from 'ethers/lib/utils'
import { useMultipleContractSingleData, useSingleCallResult, useSingleContractMultipleData } from 'lib/hooks/multicall'
import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { useSteerStakingPools, useSteerVaultsCached } from 'state/info/hooks'

import { useSteerPeripheryContract, useSteerVaultContract, useSteerVaultRegistryContract } from './useContract'

// Step 1: Define TypeScript interfaces
interface StakingToken {
  symbol: string
  name: string
  address: string
  decimals: number
}

interface VaultToken {
  name: string
  address: string
  symbol: string
}

export interface StakingPool {
  chainId: number
  stakingPool: string
  protocol: string
  factoryAddress: string
  isDualFactory: boolean
  dailyEmissionRewardA?: number
  dailyEmissionRewardB?: number
  stakingToken?: string
  rewardTokenA?: string
  rewardTokenB?: string
  periodFinish?: string
  rewardsDuration?: string
  staking?: StakingToken
  rewardTokenADetail?: StakingToken
  rewardTokenBDetail?: StakingToken
  beaconName: string
  isSteerVault: boolean
  strategyId: string
  feeTier: string
  vaultTokens: {
    token0: VaultToken
    token1: VaultToken
  }
}

function isFinished(timestampToCheck: number): boolean {
  // Get the current time in Unix timestamp (seconds since the Unix Epoch)
  const currentTimestamp = Math.floor(Date.now() / 1000)

  // Compare the given timestamp with the current timestamp
  return timestampToCheck < currentTimestamp
}

export const useFetchSteerStakingPool = (chainIdMemo?: number) => {
  const steerAvailable = isSteerChain(chainIdMemo)
  const fetchSteerStakingPools = async () => {
    if (!steerAvailable) return []
    const response = await fetch('https://9i52h964s3.execute-api.us-east-1.amazonaws.com/dev/staking-pools', {
      mode: 'cors', // Set the request's mode to 'no-cors'
    })
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }
    const data: { pools: StakingPool[] } = await response.json()
    return data?.pools?.filter(
      (item) =>
        item.chainId === chainIdMemo &&
        item.protocol?.toLocaleLowerCase() === 'arthswap' &&
        !isFinished(Number(item.periodFinish))
    )
  }

  const { isLoading, data } = useQuery({
    queryKey: ['fetchSteerStakingPools', chainIdMemo],
    queryFn: fetchSteerStakingPools,
    refetchInterval: 300000,
  })

  return { loading: isLoading, data }
}

export interface SteerVault {
  address: string
  state?: number
  strategyName: string
  vaultType?: string
  token0Address?: string
  token0Name?: string
  token0Symbol?: string
  token0Decimals?: number
  token1Address?: string
  token1Name?: string
  token1Symbol?: string
  token1Decimals?: number
  vaultName?: string
  vaultSymbol?: string
  vaultDecimals: number
  feeTier?: string
  totalLPTokensIssued?: string
  token0Balance?: string
  token1Balance?: string
  vaultCreator: string
  lowerTick?: string
  upperTick?: string
  poolAddress?: string
  sqrtPriceX96?: string
  tick?: string
  apr?: number
  tvl?: number
  totalSupply?: string
  pricePerLP?: number
}

export interface SteerPosition extends SteerVault {
  depositedBalance?: CurrencyAmount<Token>
  stakedAmount?: number
}

export interface SteerPositionWithCurrency extends SteerPosition {
  currency0?: Token
  currency1?: Token
}

export interface SteerPositionWithUserBalances extends SteerPositionWithCurrency {
  liquidity?: CurrencyAmount<Token>
  token0BalanceWallet?: CurrencyAmount<Token>
  token1BalanceWallet?: CurrencyAmount<Token>
}

export enum SteerVaultState {
  PendingApproval,
  PendingThreshold,
  Paused,
  Active,
  Retired,
}

export const useSteerVaults = (chainId: number) => {
  const steerAvailable = isSteerChain(chainId)
  const fetchSteerPools = async () => {
    const steerAPIURL = 'https://api.steer.finance'
    if (!steerAvailable) return []
    const res = await fetch(`${steerAPIURL}/getSmartPools?chainId=${chainId}&dexName=arthswap`)
    let aprData
    try {
      const aprRes = await fetch(`${steerAPIURL}/getAprs?chainId=${chainId}&dexName=arthswap`)
      aprData = await aprRes.json()
    } catch {}
    const data = await res.json()
    const vaultAPRs = aprData?.vaults ?? []
    if (data && data.pools) {
      const allVaults: any[][] = Object.values(data.pools)
      const poolAddresses = Object.keys(data.pools)
      const vaults: any[] = []
      for (const poolVaults of allVaults) {
        for (const vault of poolVaults) {
          const existingVault = vaults.find((vaultItem) => vaultItem.address === vault.vaultAddress)
          if (!existingVault) {
            const poolAddress = poolAddresses.find(
              (address) =>
                data.pools[address] && data.pools[address].find((item: any) => item.vaultAddress === vault.vaultAddress)
            )
            let poolValue
            try {
              const poolValueRes = await fetch(
                `${steerAPIURL}/pool/lp/value?chain=${chainId}&address=${vault.vaultAddress}`
              )
              poolValue = await poolValueRes.json()
            } catch {}
            vaults.push({
              address: vault.vaultAddress,
              poolAddress,
              strategyName: vault.strategyName,
              apr: vaultAPRs.find((item: any) => item.vaultAddress.toLowerCase() === vault.vaultAddress.toLowerCase())
                ?.apr?.apr,
              tvl: poolValue?.tvl,
              totalSupply: poolValue?.totalSupply,
              pricePerLP: poolValue?.pricePerLP,
            })
          }
        }
      }
      return vaults
    }
    return []
  }

  const { isLoading, data: vaults } = useQuery({
    queryKey: ['fetchSteerPools', chainId],
    queryFn: fetchSteerPools,
    refetchInterval: 300000,
  })

  const poolAddresses = useMemo(() => {
    if (!vaults) return []
    return vaults.map((vault) => vault.poolAddress).filter((item, ind, self) => self.indexOf(item) === ind)
  }, [vaults])
  const v3PoolInterface = new Interface(IArthSwapV3PoolStateABI)
  const slot0Calls = useMultipleContractSingleData(poolAddresses, v3PoolInterface, 'slot0', [])
  const slot0Items = slot0Calls.map((call, ind) => {
    const result = !call.loading && call.result && call.result.length > 0 ? call.result : undefined
    const sqrtPriceX96 = result && result.length > 0 ? result[0].toString() : undefined
    const tick = result && result.length > 1 ? result[1].toString() : undefined
    return { poolAddress: poolAddresses[ind], sqrtPriceX96, tick }
  })

  const peripheryContract = useSteerPeripheryContract()
  const vaultRegistryContract = useSteerVaultRegistryContract()
  const vaultAddresses = useMemo(() => {
    if (!vaults) return []
    return vaults.map((vault) => vault.address)
  }, [vaults])

  const vaultPositionCalls = useMultipleContractSingleData(
    vaultAddresses,
    new Interface(SteerVaultABI),
    'getPositions',
    []
  )
  const vaultPositions = vaultPositionCalls.map((call, ind) => {
    const vaultPositionData = !call.loading && call.result ? call.result : undefined
    const lowerTicks = vaultPositionData && vaultPositionData.length > 0 ? vaultPositionData[0] : undefined
    const upperTicks = vaultPositionData && vaultPositionData.length > 1 ? vaultPositionData[1] : undefined
    return { vaultAddress: vaultAddresses[ind], lowerTicks, upperTicks }
  })

  const vaultRegistryDetailCalls = useSingleContractMultipleData(
    vaultRegistryContract,
    'getVaultDetails',
    vaultAddresses.map((address) => [address])
  )

  const vaultDetailCalls = useSingleContractMultipleData(
    peripheryContract,
    'vaultDetailsByAddress',
    vaultAddresses.map((address) => [address])
  )

  const vaultDetails: SteerVault[] = vaultDetailCalls.map((call, index) => {
    const vaultAddress = vaultAddresses[index]
    const vaultRegistryDetailCall = vaultRegistryDetailCalls[index]
    const vaultRegistryData =
      !vaultRegistryDetailCall.loading && vaultRegistryDetailCall.result && vaultRegistryDetailCall.result.length > 0
        ? vaultRegistryDetailCall.result[0]
        : undefined
    const vaultData = !call.loading && call.result && call.result.length > 0 ? call.result[0] : undefined
    const vaultType = vaultData && vaultData.length > 0 ? vaultData[0] : undefined
    const token0Address = vaultData && vaultData.length > 1 ? vaultData[1] : undefined
    const token1Address = vaultData && vaultData.length > 2 ? vaultData[2] : undefined
    const vaultName = vaultData && vaultData.length > 3 ? vaultData[3] : undefined
    const vaultSymbol = vaultData && vaultData.length > 4 ? vaultData[4] : undefined
    const vaultDecimals = vaultData && vaultData.length > 5 && vaultData[5] ? Number(vaultData[5]) : 18
    const token0Name = vaultData && vaultData.length > 6 ? vaultData[6] : undefined
    const token1Name = vaultData && vaultData.length > 7 ? vaultData[7] : undefined
    const token0Symbol = vaultData && vaultData.length > 8 ? vaultData[8] : undefined
    const token1Symbol = vaultData && vaultData.length > 9 ? vaultData[9] : undefined
    const token0Decimals = vaultData && vaultData.length > 10 && vaultData[10] ? Number(vaultData[10]) : undefined
    const token1Decimals = vaultData && vaultData.length > 11 && vaultData[11] ? Number(vaultData[11]) : undefined
    const feeTier = vaultData && vaultData.length > 12 ? vaultData[12].toString() : undefined
    const totalLPIndex = 13
    const totalLPTokensIssued =
      vaultData && vaultData.length > totalLPIndex ? vaultData[totalLPIndex].toString() : undefined
    const token0BalanceIndex = 14
    const token0Balance =
      vaultData && vaultData.length > token0BalanceIndex ? vaultData[token0BalanceIndex].toString() : undefined
    const token1BalanceIndex = 15
    const token1Balance =
      vaultData && vaultData.length > token1BalanceIndex ? vaultData[token1BalanceIndex].toString() : undefined
    const vaultCreatorIndex = 16
    const vaultCreator = vaultData && vaultData.length > vaultCreatorIndex ? vaultData[vaultCreatorIndex] : undefined

    const vaultItem = vaults?.find((item) => item.address === vaultAddress)
    const slot0 = slot0Items.find((item) => vaultItem && item.poolAddress === vaultItem.poolAddress)
    const vaultPosition = vaultPositions[index]

    return {
      ...slot0,
      address: vaultAddress,
      state: vaultRegistryData && vaultRegistryData.length > 0 ? Number(vaultRegistryData[0]) : undefined,
      strategyName: vaultItem?.strategyName,
      apr: vaultItem?.apr,
      vaultType,
      token0Address,
      token1Address,
      vaultName,
      vaultSymbol,
      vaultDecimals,
      token0Name,
      token0Symbol,
      token0Decimals,
      token1Name,
      token1Symbol,
      token1Decimals,
      feeTier,
      totalLPTokensIssued,
      token0Balance,
      token1Balance,
      vaultCreator,
      lowerTick:
        vaultPosition && vaultPosition.lowerTicks && vaultPosition.lowerTicks.length > 0
          ? vaultPosition.lowerTicks[0].toString()
          : undefined,
      upperTick:
        vaultPosition && vaultPosition.upperTicks && vaultPosition.upperTicks.length > 0
          ? vaultPosition.upperTicks[0].toString()
          : undefined,
      tvl: vaultItem?.tvl,
      totalSupply: vaultItem?.totalSupply,
      pricePerLP: vaultItem?.pricePerLP,
    }
  })

  return { loading: isLoading, data: vaultDetails }
}

export const useSteerStakedPools = (chainId?: number, account?: string) => {
  const steerStakingPools = useSteerStakingPools(chainId)
  const farmAddresses = steerStakingPools.map((pool: any) => pool.stakingPool)
  const stakedAmountCalls = useMultipleContractSingleData(
    account ? farmAddresses : [],
    new Interface(STEER_STAKING_ABI),
    'balanceOf',
    [account]
  )
  const stakedAmounts = stakedAmountCalls.map((call) =>
    !call.loading && call.result && call.result.length > 0 ? Number(formatUnits(call.result[0])) : 0
  )
  return useMemo(
    () =>
      steerStakingPools
        .map((farm: any, ind: number) => {
          return { ...farm, stakedAmount: stakedAmounts[ind] }
        })
        .filter((farm: any) => farm.stakedAmount > 0),
    [stakedAmounts, steerStakingPools]
  )
}
export function useSteerPosition(vaultAddress?: string): { loading: boolean; data?: SteerPositionWithUserBalances } {
  const { chainId, account } = useWeb3React()
  const steerVaults = useSteerVaultsCached(chainId)
  const steerVaultContract = useSteerVaultContract(vaultAddress)
  const vaultBalanceRes = useSingleCallResult(steerVaultContract, 'balanceOf', [account])
  const vaultBalance = !vaultBalanceRes.loading && vaultBalanceRes.result ? vaultBalanceRes.result[0] : '0'
  const vault = steerVaults.find((item) => item.address.toLowerCase() === vaultAddress?.toLowerCase())
  const vaultAsToken = useMemo(
    () =>
      vault && chainId
        ? new Token(chainId, vault.address, vault.vaultDecimals, vault.vaultSymbol, vault.vaultName)
        : undefined,
    [chainId, vault]
  )
  const liquidity = useMemo(
    () => (vaultAsToken ? CurrencyAmount.fromRawAmount(vaultAsToken, vaultBalance) : undefined),
    [vaultAsToken, vaultBalance]
  )
  const vaultTotalSupply = parseUnits(vault?.totalSupply?.toString() ?? '0', vault?.vaultDecimals ?? 18)
  const steerToken0VaultBalance = vault?.token0Balance
  const steerToken1VaultBalance = vault?.token1Balance
  const token0Balance =
    liquidity && vaultTotalSupply && steerToken0VaultBalance
      ? new Fraction(steerToken0VaultBalance).multiply(liquidity).divide(vaultTotalSupply.toString())
      : undefined
  const token1Balance =
    liquidity && vaultTotalSupply && steerToken1VaultBalance
      ? new Fraction(steerToken1VaultBalance).multiply(liquidity).divide(vaultTotalSupply.toString())
      : undefined

  const currency0 = useMemo(
    () =>
      vault && chainId && vault.token0Address && vault.token0Decimals
        ? new Token(chainId, vault.token0Address, vault.token0Decimals, vault.token0Symbol, vault.token0Name)
        : undefined,
    [chainId, vault]
  )

  const currency1 = useMemo(
    () =>
      vault && chainId && vault.token1Address && vault.token1Decimals
        ? new Token(chainId, vault.token1Address, vault.token1Decimals, vault.token1Symbol, vault.token1Name)
        : undefined,
    [chainId, vault]
  )

  const token0BalanceWallet = useMemo(
    () => (currency0 && token0Balance ? CurrencyAmount.fromRawAmount(currency0, token0Balance.quotient) : undefined),
    [currency0, token0Balance]
  )

  const token1BalanceWallet = useMemo(
    () => (currency1 && token1Balance ? CurrencyAmount.fromRawAmount(currency1, token1Balance.quotient) : undefined),
    [currency1, token1Balance]
  )
  currency1 && token1Balance ? CurrencyAmount.fromRawAmount(currency1, token1Balance.quotient) : undefined

  return {
    loading: !steerVaults.length || vaultBalanceRes.loading,
    data: useMemo(
      () =>
        vaultAddress && vault
          ? {
              ...vault,
              liquidity,
              token0BalanceWallet,
              token1BalanceWallet,
              currency0,
              currency1,
            }
          : undefined,
      [vaultAddress, vault, liquidity, token0BalanceWallet, token1BalanceWallet, currency0, currency1]
    ),
  }
}

export const getSteerRatio = (tokenType: number, steerVault: SteerVault) => {
  let steerRatio
  const steerVaultToken0BalanceNum = new Fraction(steerVault.token0Balance ?? '0')
  const steerVaultToken1BalanceNum = new Fraction(steerVault.token1Balance ?? '0')

  if (tokenType === 1) {
    if (steerVaultToken0BalanceNum.greaterThan(0)) {
      steerRatio = steerVaultToken1BalanceNum.divide(steerVaultToken0BalanceNum)
    } else {
      steerRatio = steerVaultToken1BalanceNum
    }
  } else {
    if (steerVaultToken1BalanceNum.greaterThan(0)) {
      steerRatio = steerVaultToken0BalanceNum.divide(steerVaultToken1BalanceNum)
    } else {
      steerRatio = steerVaultToken0BalanceNum
    }
  }
  return steerRatio
}
