import { Trans } from '@lingui/macro'
import RangeBadge from 'components/Badge/RangeBadge'
import { LightCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import { Break } from 'components/earn/styled'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import { calculatePositionWidth, percentageToMultiplier } from 'components/PresetSelector'
import { RowBetween, RowFixed } from 'components/Row'
import { SteerPositionWithUserBalances } from 'hooks/useSteerData'
import { ThemedText } from 'theme'

export const SteerPositionPreview = ({ position }: { position: SteerPositionWithUserBalances }) => {
  const currency0 = position.currency0
  const currency1 = position.currency1
  const currency0Amount = position.token0BalanceWallet
  const currency1Amount = position.token1BalanceWallet

  const minTick = Number(position.lowerTick ?? 0)
  const maxTick = Number(position.upperTick ?? 0)
  const currentTick = Number(position.tick ?? 0)

  const outOfRange: boolean =
    position && position.lowerTick && position.upperTick
      ? Number(position.tick) < Number(position.lowerTick) || Number(position.tick) >= Number(position.upperTick)
      : false

  const positionWidthPercent = calculatePositionWidth(currentTick, minTick, maxTick)
  const strategy = position.strategyName.toLowerCase().includes('stable')
    ? 'Stable'
    : percentageToMultiplier(positionWidthPercent) > 1.2
    ? 'Wide'
    : 'Narrow'
  return (
    <AutoColumn gap="md" style={{ marginTop: '0.5rem' }}>
      <RowBetween style={{ marginBottom: '0.5rem' }}>
        <RowFixed>
          <DoubleCurrencyLogo
            currency0={currency0 ?? undefined}
            currency1={currency1 ?? undefined}
            size={24}
            margin={true}
          />
          <ThemedText.DeprecatedLabel ml="10px" fontSize="24px">
            {currency1?.symbol} / {currency0?.symbol}
          </ThemedText.DeprecatedLabel>
        </RowFixed>
        <RangeBadge removed={false} inRange={!outOfRange} />
      </RowBetween>
      <LightCard>
        <AutoColumn gap="md">
          <RowBetween>
            <RowFixed>
              <CurrencyLogo currency={currency1} />
              <ThemedText.DeprecatedLabel ml="8px">{currency1?.symbol}</ThemedText.DeprecatedLabel>
            </RowFixed>
            <RowFixed>
              <ThemedText.DeprecatedLabel mr="8px">{currency1Amount?.toSignificant(4)}</ThemedText.DeprecatedLabel>
            </RowFixed>
          </RowBetween>
          <RowBetween>
            <RowFixed>
              <CurrencyLogo currency={currency0} />
              <ThemedText.DeprecatedLabel ml="8px">{currency0?.symbol}</ThemedText.DeprecatedLabel>
            </RowFixed>
            <RowFixed>
              <ThemedText.DeprecatedLabel mr="8px">{currency0Amount?.toSignificant(4)}</ThemedText.DeprecatedLabel>
            </RowFixed>
          </RowBetween>
          <Break />
          <RowBetween>
            <ThemedText.DeprecatedLabel>
              <Trans>Strategy</Trans>
            </ThemedText.DeprecatedLabel>
            <ThemedText.DeprecatedLabel>
              <Trans>{strategy}</Trans>
            </ThemedText.DeprecatedLabel>
          </RowBetween>
        </AutoColumn>
      </LightCard>
    </AutoColumn>
  )
}
