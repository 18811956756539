import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import AssetLogo from 'components/Logo/AssetLogo';
import BigNumber from 'bignumber.js'
import { UserRewardWithMerkl } from './UserRewardWithMerkl';
import { useFeeAprOfByTokenAddress } from 'hooks/useFeeAprOfStakingTokenAddress';
import { Distribution, PoolPosition } from 'hooks/useMerkleData';
import { ButtonEmphasis, ButtonSize, ThemeButton } from 'components/Button';

interface PoolBoxProps {
  tokenA?: VaultToken;
  tokenB?: VaultToken;
  stakingTokenAddress?: string
  chainId?: number;
  tvl?: number | null;
  apr?: number | null;
  size?: 'small' | 'large';
  cta?: React.ReactNode
  poolFee?: number | string | null
  feeApr?: any
  mainParameter?: string;
  account?: string;
  rewardToken?: string;
  distributionData?: Distribution[]
  usersPositions?: PoolPosition[] | null
}

interface VaultToken {
  address?: string;
  symbol?: string;
}
const Box2 = styled.div`
margin-top: 10px;
position: relative;
background-color: ${({ theme }) => theme.backgroundModule};
border-radius: 12px;
padding: 16px;
color: ${({ theme }) => theme.textSecondary};
font-size: 14px;
font-weight: 500;

&:before {
  box-sizing: border-box;
  background-size: 100%;
  border-radius: inherit;

  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  pointer-events: none;
  content: '';
  border: 1px solid ${({ theme }) => theme.backgroundModule};
}

&:hover {
  background-color: ${({ theme }) => theme.backgroundOutline};
}
&:hover:before {
  border-color: ${({ theme }) => theme.stateOverlayHover};
}

&:focus-within:before {
  border-color: ${({ theme }) => theme.stateOverlayPressed};
}
`;
const Flex = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
gap: 10px;
`;

const AssetLogoPair = styled.div`
display: flex;
flex-direction: row;
gap: 5px
`

const ColumnFlex = styled.div`
display: flex;
flex-direction: column;
`
const HeaderButton = styled(ThemeButton)`
  border-color: transparent;
  border-radius: 12px;
  border-style: solid;
  border-width: 1px;
  height: 40px;
  margin-top: 8px;
`

export const Farm: React.FC<PoolBoxProps> = ({ tokenA, tokenB, chainId, tvl, apr, poolFee, size, cta, mainParameter, account, distributionData, usersPositions }) => {
  const { feeApr, stakingTokenAddress } = useFeeAprOfByTokenAddress(chainId, tokenA?.address, tokenB?.address, (Number(poolFee) * 10000).toString())
  const totalApr = BigNumber(apr?.toString() || '0').plus(feeApr || '0')
  const poolValue = { tvl: tvl }
  const poolLink = useMemo(() => `/#/add/${tokenA?.address}/${tokenB?.address}/${Number(poolFee || 0) * 10000}`, [tokenA?.address, tokenB?.address, poolFee])
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const handleToggleDetails = () => {
    setShowDetails(!showDetails);
  };

  return (
    <Box2>
      <Flex>
        <ColumnFlex>
          <AssetLogoPair>
            <AssetLogo
              chainId={chainId}
              address={tokenA?.address ?? ''}
              symbol={tokenA?.symbol ?? ''} size={size === 'small' ? '24px' : '32px'}
              hideL2Icon={true}
            />
            <AssetLogo
              chainId={chainId}
              address={tokenB?.address ?? ''}
              symbol={tokenB?.symbol ?? ''} size={size === 'small' ? '24px' : '32px'}
              hideL2Icon={true}
              style={{ marginLeft: '3px' }}
            />
          </AssetLogoPair>
          <div style={{ fontSize: size === 'small' ? '12px' : '14px' }}>{tokenA?.symbol ?? ''}/{tokenB?.symbol ?? ''}</div>
        </ColumnFlex>
        <ColumnFlex>
          <span style={{ fontSize: size === 'small' ? '12px' : '14px' }}>APR</span>
          {totalApr && totalApr?.gt(0) ? <span style={{ fontSize: size === 'small' ? '12px' : '14px', color: "white" }}>{totalApr.multipliedBy(100).toFixed(1)} %</span> : <span>loading...</span>}
        </ColumnFlex>
        {size === 'large' || !size && (
          <ColumnFlex>
            <span>Liquidity</span>
            {poolValue?.tvl ? <span style={{ color: 'white' }}>${poolValue?.tvl?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</span> : <span style={{ color: 'white' }}>loading...</span>}
          </ColumnFlex>
        )}
        <ColumnFlex>
          <span style={{ fontSize: size === 'small' ? '12px' : '14px' }}>Pool Fee</span>
          {poolFee ? <span style={{ fontSize: size === 'small' ? '12px' : '14px', color: "white" }}>{poolFee}</span> : <span>loading...</span>}
        </ColumnFlex>
        <ColumnFlex>
          <HeaderButton
            size={ButtonSize.medium}
            emphasis={ButtonEmphasis.medium}
            onClick={handleToggleDetails}
          >
            {showDetails ? 'close' : 'show details'}
          </HeaderButton>
        </ColumnFlex>
      </Flex>
      <UserRewardWithMerkl
        chainId={chainId}
        mainParameter={mainParameter}
        account={account}
        poolLink={poolLink}
        lpTokenName={`${tokenA?.symbol ?? ''}/${tokenB?.symbol ?? ''}`}
        steerStakingAddress={stakingTokenAddress}
        distributionData={distributionData}
        display={showDetails}
        usersPosition={usersPositions}
      />
    </Box2>
  );
};
