import { SupportedChainId } from 'constants/chains'
export const CHAIN_SUBGRAPH_V3_URL: Record<number, string> = {
  [SupportedChainId.MAINNET]: 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3',

  [SupportedChainId.ARBITRUM_ONE]: 'https://api.thegraph.com/subgraphs/name/ianlapham/arbitrum-minimal',

  [SupportedChainId.OPTIMISM]: 'https://api.thegraph.com/subgraphs/name/ianlapham/optimism-post-regenesis',

  [SupportedChainId.POLYGON]: 'https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-v3-polygon',

  [SupportedChainId.CELO]: 'https://api.thegraph.com/subgraphs/name/jesse-sawa/uniswap-celo',

  [SupportedChainId.BNB]: 'https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-v3-bsc',

  [SupportedChainId.ASTR]: 'https://squid.subsquid.io/arthswap-v3-astar-squid/v/v1/graphql',

  [SupportedChainId.SHIBUYA]: 'https://squid.subsquid.io/arthswap-v3-shibuya-squid/v/v1/graphql',

  [SupportedChainId.ZKATANA]: 'https://api.studio.thegraph.com/proxy/66105/arthswap-v3-zkatana/version/latest',

  [SupportedChainId.ZKEVM]: 'https://api.studio.thegraph.com/proxy/66105/arthswap-v3-zkevm/version/latest',
}

export const CHAIN_SUBGRAPH_V2_URL: Record<number, string> = {
  [SupportedChainId.ASTR]: 'https://squid.subsquid.io/arthswap-squid/v/v1/graphql',

  [SupportedChainId.SHIBUYA]: 'https://squid.subsquid.io/arthswap-shibuya-squid/v/v2/graphql',
}
