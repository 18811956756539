import { createSlice } from '@reduxjs/toolkit'
import { StakingPool, SteerVault } from 'hooks/useSteerData'

interface InfoState {
  readonly v2Tvl: Record<number, number | null>
  readonly v3Tvl: Record<number, number | null>
  readonly v2TxVolume: Record<number, number | null>
  readonly v3TxVolume: Record<number, number | null>
  readonly steerVaults: { [key: number]: SteerVault[] | null }
  readonly steerStakingPools: { [key: number]: StakingPool[] | null }
  readonly stakingApr: Record<number, Record<string, number | null>>
}

const initialState: InfoState = {
  v2Tvl: {},
  v3Tvl: {},
  v2TxVolume: {},
  v3TxVolume: {},
  steerVaults: {},
  steerStakingPools: {},
  stakingApr: {},
}

const infoSlice = createSlice({
  name: 'info',
  initialState,
  reducers: {
    setV3Tvl(state, { payload: { value, chainId } }) {
      state.v3Tvl[chainId] = value
    },
    setV2Tvl(state, { payload: { value, chainId } }) {
      state.v2Tvl[chainId] = value
    },
    setV3TxVolume(state, { payload: { value, chainId } }) {
      state.v3TxVolume[chainId] = value
    },
    setV2TxVolume(state, { payload: { value, chainId } }) {
      state.v2TxVolume[chainId] = value
    },
    setSteerVaults(state, { payload: { value, chainId } }) {
      state.steerVaults[chainId] = value
    },
    setSteerStakingPools(state, { payload: { value, chainId } }) {
      state.steerStakingPools[chainId] = value
    },
    setStakingApr(state, { payload: { value, chainId, vaultAddress } }) {
      state.stakingApr[chainId] = state.stakingApr[chainId] || {}
      state.stakingApr[chainId][vaultAddress] = value
    },
  },
})

export const { setV3Tvl, setV2Tvl, setV3TxVolume, setV2TxVolume, setSteerVaults, setSteerStakingPools, setStakingApr } =
  infoSlice.actions
export default infoSlice.reducer
