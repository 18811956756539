import { Token } from '@uniswap/sdk-core'
import BigNumber from 'bignumber.js'
import { useUSDPrice } from 'hooks/useUSDPrice'
import { useTokenBalance } from 'lib/hooks/useCurrencyBalance'
import { useEffect, useMemo } from 'react'
import { useAppDispatch } from 'state/hooks'
import { useSteerVaults } from 'state/info/hooks'
import { setStakingApr } from 'state/info/reducer'

import { useTokenAmount } from './useTokenAmount'

interface PoolBoxProps {
  tokenA?: StakingToken
  tokenB?: StakingToken
  stakingTokenAddress?: string
  chainId?: number
  dailyEmissionRewardA?: number
  dailyEmissionRewardB?: number
  stakingPool?: string
}

interface StakingToken {
  symbol: string
  name: string
  address: string
  decimals: number
}

interface PoolValueResponse {
  tvl: number
  totalSupply: string
  pricePerLP: number
}

export const useFarmApr = ({
  tokenA,
  tokenB,
  stakingTokenAddress,
  chainId,
  dailyEmissionRewardA,
  dailyEmissionRewardB,
  stakingPool,
}: PoolBoxProps) => {
  const dispatch = useAppDispatch()
  const steerVaults = useSteerVaults()[chainId || 0]
  const poolValue: PoolValueResponse | undefined = useMemo(() => {
    if (!steerVaults || !stakingTokenAddress) return
    const vault = steerVaults.find((x) => x.address.toLocaleLowerCase() === stakingTokenAddress.toLocaleLowerCase())
    return {
      tvl: vault?.tvl || 0,
      totalSupply: vault?.totalSupply || '0',
      pricePerLP: vault?.pricePerLP || 0,
    }
  }, [steerVaults, stakingTokenAddress])
  const tokenAmountA = useTokenAmount({ chainId, tokenAddress: tokenA?.address, decimals: tokenA?.decimals })
  const tokenAmountB = useTokenAmount({ chainId, tokenAddress: tokenB?.address, decimals: tokenB?.decimals })
  const priceA = useUSDPrice(tokenAmountA)
  const priceB = useUSDPrice(tokenAmountB)
  const lpToken = useMemo(() => {
    if (!chainId || !stakingTokenAddress) return
    return new Token(chainId, stakingTokenAddress, 18)
  }, [chainId, stakingTokenAddress])
  const balance = useTokenBalance(stakingPool, lpToken)

  const stakedAmount = useMemo(() => {
    const balanceBN = BigNumber(balance?.numerator.toString() || 0)
    const lpTokenPriceBN = BigNumber(poolValue?.pricePerLP.toString() || 0)
    return balanceBN.div(1e18).multipliedBy(lpTokenPriceBN)
  }, [balance, poolValue])

  const rewardApr = useMemo(() => {
    if (!stakedAmount) return undefined
    const rewardTokenAUSD = priceA?.data ? (dailyEmissionRewardA || 0) * priceA.data : 0
    const rewardTokenBUSD = priceB?.data ? (dailyEmissionRewardB || 0) * priceB.data : 0
    const rewardsA = rewardTokenAUSD > 0 ? BigNumber(rewardTokenAUSD * 365).div(stakedAmount) : BigNumber(0)
    const rewardsB = rewardTokenBUSD > 0 ? BigNumber(rewardTokenBUSD * 365).div(stakedAmount) : BigNumber(0)
    return {
      aprA: rewardsA,
      aprB: rewardsB,
      apr: rewardsA.plus(rewardsB),
    }
  }, [dailyEmissionRewardA, dailyEmissionRewardB, priceA, priceB, stakedAmount])
  useEffect(() => {
    if (rewardApr?.apr.toNumber() && stakingTokenAddress) {
      dispatch(
        setStakingApr({
          chainId,
          vaultAddress: stakingTokenAddress.toLocaleLowerCase(),
          value: rewardApr.apr.toNumber() * 100,
        })
      )
    }
  }, [chainId, dispatch, rewardApr?.apr, stakingTokenAddress])
  return { dailyEmissionRewardA, dailyEmissionRewardB, rewardApr, stakedAmount, poolValue }
}
