import { Currency, Token } from '@uniswap/sdk-core';
import { useCallback, useState } from 'react';
import useCurrencyLogoURIs from 'lib/hooks/useCurrencyLogoURIs'
import { useWeb3React } from '@web3-react/core'

export default function useAddTokenToMetamask(
  currencyToAdd: Currency | undefined,
): {
  addToken: () => void;
  isEnable: boolean;
} {
  const { provider: library } = useWeb3React();

  const token: Token | undefined = currencyToAdd?.wrapped;
  const logoURL = useCurrencyLogoURIs(token)[0]

  const addToken = useCallback(() => {
    if (
      library &&
      library.provider.isMetaMask &&
      library.provider.request &&
      token
    ) {
      library.provider
        .request({
          method: 'wallet_watchAsset',
          params: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore // need this for incorrect ethers provider type
            type: 'ERC20',
            options: {
              address: token.address,
              symbol: token.symbol,
              decimals: token.decimals,
              image: logoURL,
            },
          },
        })
    }
  }, [library, token]);

  return { addToken, isEnable: !!token && !!library?.provider, };
}
