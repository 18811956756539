import { ApolloClient, gql, InMemoryCache, NormalizedCacheObject } from '@apollo/client'
import { SupportedChainId } from 'constants/chains'
import { CHAIN_SUBGRAPH_V2_URL, CHAIN_SUBGRAPH_V3_URL } from 'constants/graphql'

export const v3Client: Record<number, ApolloClient<NormalizedCacheObject>> = {
  [SupportedChainId.ASTR]: new ApolloClient({
    cache: new InMemoryCache(),
    uri: CHAIN_SUBGRAPH_V3_URL[SupportedChainId.ASTR],
    headers: {
      'Content-Type': 'application/json',
    },
  }),
  [SupportedChainId.SHIBUYA]: new ApolloClient({
    cache: new InMemoryCache(),
    uri: CHAIN_SUBGRAPH_V3_URL[SupportedChainId.SHIBUYA],
    headers: {
      'Content-Type': 'application/json',
    },
  }),
  [SupportedChainId.ZKEVM]: new ApolloClient({
    cache: new InMemoryCache(),
    uri: CHAIN_SUBGRAPH_V3_URL[SupportedChainId.ZKEVM],
    headers: {
      'Content-Type': 'application/json',
    },
  }),
}

export const v2Client: Record<number, ApolloClient<NormalizedCacheObject>> = {
  [SupportedChainId.ASTR]: new ApolloClient({
    cache: new InMemoryCache(),
    uri: CHAIN_SUBGRAPH_V2_URL[SupportedChainId.ASTR],
    headers: {
      'Content-Type': 'application/json',
    },
  }),
  [SupportedChainId.SHIBUYA]: new ApolloClient({
    cache: new InMemoryCache(),
    uri: CHAIN_SUBGRAPH_V2_URL[SupportedChainId.SHIBUYA],
    headers: {
      'Content-Type': 'application/json',
    },
  }),
}

export const v3TvlQuery = gql`
  query PoolTVLs {
    pools(orderBy: totalValueLockedUSD_DESC, where: { totalValueLockedUSD_gt: "0.01" }) {
      totalValueLockedUSD
    }
  }
`

export const v3TvlSubgraphQuery = gql`
  query PoolTVLs {
    pools(orderBy: totalValueLockedUSD, where: { totalValueLockedUSD_gt: "0.01" }, orderDirection: desc) {
      totalValueLockedUSD
    }
  }
`

export const v2TvlQuery = gql`
  query PairTVLs {
    pairs(where: { reserveUSD_gte: "0.01" }, orderBy: reserveUSD_DESC) {
      reserveUSD
    }
  }
`

export const v3TxVolumes = gql`
  query Volumes($date: DateTime!) {
    poolHourData(where: { volumeUSD_gte: "0.01", periodStartUnix_gte: $date }) {
      volumeUSD
    }
  }
`

export const v3SubgraphTxVolumes = gql`
  query Volumes($date: Int!) {
    poolHourDatas(where: { volumeUSD_gte: "0.01", periodStartUnix_gte: $date }) {
      volumeUSD
    }
  }
`

export const v2TxVolumes = gql`
  query Volumes($timestamp: BigInt!) {
    pairHourData(where: { hourlyVolumeUSD_gte: "0.01", hourStartUnix_gte: $timestamp }) {
      hourlyVolumeUSD
    }
  }
`
