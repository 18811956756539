import React, { useState, useEffect, useMemo } from 'react';
import { ethers } from 'ethers';
import axios from 'axios';
import { useWeb3React } from '@web3-react/core';
import { ButtonEmphasis, ButtonSize, ThemeButton } from 'components/Button';
import styled from 'styled-components';
import { useFetchUsersAllRewards } from 'hooks/useFetchUserRewards';
import AssetLogo from 'components/Logo/AssetLogo';

interface ClaimData {
  claim: string;
  proof: string[];
}

const Flex = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
gap: 10px;
`;

const ColumnFlex = styled.div`
display: flex;
flex-direction: column;
`

const Box = styled.div`
  display: flex;
  gap: 20px;
  padding: 20px;
  background-color: ${({ theme }) => theme.backgroundOutline};
  color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Add some shadow for a nicer look */
`;

const HeaderButton = styled(ThemeButton)`
  border-color: transparent;
  border-radius: 12px;
  border-style: solid;
  border-width: 1px;
  height: 40px;
  margin-top: 8px;
`
const useClaims = (signer?: string, chainId?: number) => {
  const [claimsData, setClaimsData] = useState<Record<string, ClaimData>>({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!signer || !chainId) return;
      try {
        const response = await axios.get(`https://api.angle.money/v2/merkl?chainIds[]=${chainId}&user=${signer}`, {
          timeout: 5000,
        });
        const data: Record<string, ClaimData> = response.data[chainId]?.transactionData;
        if (data) {
          setClaimsData(data);
        } else {
          throw new Error("No data found for this chainId");
        }
      } catch (err) {
        setError(err.message || "Error fetching data from API");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [signer, chainId]);

  return { claimsData, isLoading, error };
};

interface Props {
  chainId?: number;
  address?: string;
  symbol?: string;
  unclaimed?: string;
}

export const Rewards: React.FC<Props> = ({ chainId, address, symbol, unclaimed }) => {
  const Flex = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
gap: 10px;
`;

  return (
    <Flex style={{ padding: '5px', marginTop: '5px' }}>
      <AssetLogo
        chainId={chainId}
        address={address ?? ''}
        symbol={symbol ?? ''}
        size={'32px'}
        hideL2Icon={true}
      />
      <div>{unclaimed}</div>
      <div >{symbol ?? ''}</div>
    </Flex>
  )
}

export const ClaimsComponent: React.FC<Props> = () => {
  const { provider, account, chainId } = useWeb3React()
  const { claimsData, isLoading, error } = useClaims(account, chainId);
  const [isLoadingClaimButton, setIsLoading] = useState<boolean>(false)
  const { data, error: e } = useFetchUsersAllRewards(chainId, account)
  const claimContractAddress = useMemo(() => {
    if (chainId === 3776) {
      return "0xA7c167f58833c5e25848837f45A1372491A535eD";
    }
    return "0x3Ef3D8bA38EBe18DB133cEc108f4D14CE00Dd9Ae";
  }, [chainId])

  const handleSubmit = async () => {
    if (!Object.keys(claimsData).length) return;
    setIsLoading(true)
    const tokens = Object.keys(claimsData).filter((k) => claimsData[k].proof !== undefined);
    const claims = tokens.map((t) => claimsData[t].claim);
    const proofs = tokens.map((t) => claimsData[t].proof);
    const distributorInterface = new ethers.utils.Interface([
      "function claim(address[] calldata users, address[] calldata tokens, uint256[] calldata amounts, bytes32[][] calldata proofs) external",
    ]);
    const contract = new ethers.Contract(claimContractAddress, distributorInterface, provider?.getSigner());

    try {
      const transaction = await contract.claim(
        tokens.map(() => account),
        tokens,
        claims,
        proofs as string[][],
      );
      await transaction.wait();
    } catch (err) {
      console.error(err);
      // alert(`Error submitting claim: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  if (account === undefined || chainId === undefined || !provider) {
    return <p>Connect your wallet to claim rewards</p>;
  }

  if (isLoading) {
    return <p>Loading...</p>;
  }
  if (error) {
    return (<>
      <p>Reload page again</p>
      <p>Loading Error: {error}</p>
    </>);
  }

  return (
    <div>
      {data && (
        <Box>
          <ColumnFlex>
            <div>Unclaimed Rewards</div>
            <div>
              <p style={{ fontSize: '10px' }}>
                ※ Unclaimed rewards have a delay and may take up to 8 hours to be displayed.
              </p>
            </div>
            <Flex style={{ marginTop: '5px' }}>
              {data.map((reward, i) => {
                return (
                  <Box key={i}>
                    <Rewards chainId={chainId} address={reward?.id} symbol={reward?.symbol ?? ''} unclaimed={reward.unclaimed} />
                  </Box>
                )
              })}
            </Flex>
            <HeaderButton
              size={ButtonSize.medium}
              emphasis={ButtonEmphasis.medium}
              disabled={!Object.keys(claimsData).length || isLoadingClaimButton}
              onClick={handleSubmit}
            >
              Claim All Rewards
            </HeaderButton>
          </ColumnFlex>
        </Box>
      )}
    </div>
  );
};
