import { Trade } from '@arth-s/router-sdk'
import { Currency, Percent, TradeType } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { useGetConnection } from 'connection'
import { TRANSACTION_FAILED, TRANSACTION_INITIATED, TRANSACTION_SUCCESS } from 'constants/analytics'
import { PermitSignature } from 'hooks/usePermitAllowance'
import { useMemo } from 'react'
import trackEvent from 'utils/analytics'

import { useTransactionAdder } from '../state/transactions/hooks'
import { TransactionType } from '../state/transactions/types'
import { currencyId } from '../utils/currencyId'
import useTransactionDeadline from './useTransactionDeadline'
import { useUniversalRouterSwapCallback } from './useUniversalRouter'

// returns a function that will execute a swap, if the parameters are all valid
// and the user has approved the slippage adjusted input amount for the trade
export function useSwapCallback(
  trade: Trade<Currency, Currency, TradeType> | undefined, // trade to execute, required
  fiatValues: { amountIn: number | undefined; amountOut: number | undefined }, // usd values for amount in and out, logged for analytics
  allowedSlippage: Percent, // in bips
  permitSignature: PermitSignature | undefined
): { callback: null | (() => Promise<string>) } {
  const { connector, account } = useWeb3React()
  const deadline = useTransactionDeadline()

  const addTransaction = useTransactionAdder()

  const getConnection = useGetConnection()
  const connection = getConnection(connector)

  const universalRouterSwapCallback = useUniversalRouterSwapCallback(trade, fiatValues, {
    slippageTolerance: allowedSlippage,
    deadline,
    permit: permitSignature,
  })
  const swapCallback = universalRouterSwapCallback

  const callback = useMemo(() => {
    if (!trade || !swapCallback) return null

    const eventData = {
      type: 'swap',
      account,
      tradeType: TradeType.EXACT_INPUT,
      wallet: connection.getName(),
      tokenA: trade.inputAmount.currency.symbol,
      inputAmt: +trade.inputAmount.toFixed(4),
      inputAmtUsdc: fiatValues.amountIn || 0,
      tokenB: trade.outputAmount.currency.symbol,
      outputAmt: +trade.outputAmount.toFixed(4),
      outputAmtUsdc: fiatValues.amountOut || 0,
    }

    trackEvent(TRANSACTION_INITIATED, eventData)

    return () =>
      swapCallback()
        .then((response) => {
          addTransaction(
            response,
            trade.tradeType === TradeType.EXACT_INPUT
              ? {
                  type: TransactionType.SWAP,
                  tradeType: TradeType.EXACT_INPUT,
                  inputCurrencyId: currencyId(trade.inputAmount.currency),
                  inputCurrencyAmountRaw: trade.inputAmount.quotient.toString(),
                  expectedOutputCurrencyAmountRaw: trade.outputAmount.quotient.toString(),
                  outputCurrencyId: currencyId(trade.outputAmount.currency),
                  minimumOutputCurrencyAmountRaw: trade.minimumAmountOut(allowedSlippage).quotient.toString(),
                }
              : {
                  type: TransactionType.SWAP,
                  tradeType: TradeType.EXACT_OUTPUT,
                  inputCurrencyId: currencyId(trade.inputAmount.currency),
                  maximumInputCurrencyAmountRaw: trade.maximumAmountIn(allowedSlippage).quotient.toString(),
                  outputCurrencyId: currencyId(trade.outputAmount.currency),
                  outputCurrencyAmountRaw: trade.outputAmount.quotient.toString(),
                  expectedInputCurrencyAmountRaw: trade.inputAmount.quotient.toString(),
                }
          )
          trackEvent(TRANSACTION_SUCCESS, {
            ...eventData,
            txHash: response.hash,
          })
          return response.hash
        })
        .catch((error) => {
          trackEvent(TRANSACTION_FAILED, {
            ...eventData,
            reason: error?.message || 'N/A',
          })
          throw error
        })
  }, [
    account,
    addTransaction,
    allowedSlippage,
    connection,
    fiatValues.amountIn,
    fiatValues.amountOut,
    swapCallback,
    trade,
  ])

  return {
    callback,
  }
}
