import { createMulticall, ListenerOptions } from '@arth-s/redux-multicall'
import { useWeb3React } from '@web3-react/core'
import { SupportedChainId } from 'constants/chains'
import { useInterfaceMulticall } from 'hooks/useContract'
import useBlockNumber from 'lib/hooks/useBlockNumber'
import { useMemo } from 'react'

const multicall = createMulticall()

export default multicall

function getBlocksPerFetchForChainId(chainId: number | undefined): number {
  switch (chainId) {
    case SupportedChainId.ARBITRUM_ONE:
    case SupportedChainId.OPTIMISM:
      return 15
    case SupportedChainId.ZKEVM:
    case SupportedChainId.ZKATANA:
    case SupportedChainId.BNB:
    case SupportedChainId.CELO:
    case SupportedChainId.CELO_ALFAJORES:
      return 5
    default:
      return 1
  }
}

function getChunkGasLimitForChainId(chainId: number | undefined): number {
  switch (chainId) {
    case SupportedChainId.ZKEVM:
    case SupportedChainId.ZKATANA:
      return 50_000_000
    default:
      return 100_000_000
  }
}

export function MulticallUpdater() {
  const { chainId } = useWeb3React()
  const latestBlockNumber = useBlockNumber()
  const contract = useInterfaceMulticall()
  const listenerOptions: ListenerOptions = useMemo(
    () => ({
      blocksPerFetch: getBlocksPerFetchForChainId(chainId),
    }),
    [chainId]
  )
  const chunkGasLimit = useMemo(() => getChunkGasLimitForChainId(chainId), [chainId])

  return (
    <multicall.Updater
      chainId={chainId}
      latestBlockNumber={latestBlockNumber}
      contract={contract}
      listenerOptions={listenerOptions}
      chunkGasLimit={chunkGasLimit}
    />
  )
}
