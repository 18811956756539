import AssetLogo from "components/Logo/AssetLogo";
import { useFetchUserRewards } from "hooks/useFetchUserRewards";
import { Distribution, PoolPosition } from "hooks/useMerkleData";
import { useMemo } from "react";
import styled from "styled-components";
import { ExternalLinkIcon, ThemedText } from "theme";

const Flex = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
gap: 10px;
`;


const ColumnFlex = styled.div`
display: flex;
flex-direction: column;
`

const Box = styled.div`
  display: flex;
  gap: 20px;
  padding: 20px;
  background-color: ${({ theme }) => theme.backgroundOutline};
  color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Add some shadow for a nicer look */
`;

const ParentBox = styled.div`
display: flex;
flex-direction: row;
justify-content: space-evenly;
gap: 20px;
`;


export interface UserRewardWithMerklProps {
  chainId?: number;
  mainParameter?: string;
  account?: string;
  poolLink: string;
  lpTokenName: string;
  steerStakingAddress?: string
  distributionData?: Distribution[]
  display?: boolean
  usersPosition?: PoolPosition[] | null
}
function isFinishedCampaign(endTimeTimestamp: number): boolean {
  // Get the current timestamp in seconds
  const currentTimestamp = Math.floor(Date.now() / 1000);

  // Check if the current time is past the campaign's end time
  return currentTimestamp >= endTimeTimestamp;
}

function calculateTotalLiquidity(positions: PoolPosition[]): number {
  return positions.reduce((total, position) => total + position.totalLiquidity, 0);
}
export const UserRewardWithMerkl: React.FC<UserRewardWithMerklProps> = ({
  chainId,
  mainParameter,
  account,
  poolLink,
  lpTokenName,
  steerStakingAddress,
  distributionData,
  display,
  usersPosition
}) => {
  const { data, error, loading } = useFetchUserRewards(chainId, mainParameter, account);
  const liveCampaigns = useMemo(() => distributionData?.filter(data => !isFinishedCampaign(data.endTimestamp)), [distributionData])
  if (display === false) {
    return null;
  }

  return (
    <>
      <ParentBox style={{ marginTop: "10px", overflowX: 'scroll' }}>
        <ColumnFlex>
          <Box style={{ marginTop: '5px', marginBottom: '10px' }}>
            <ColumnFlex>
              {!liveCampaigns || liveCampaigns?.length === 0 && <ThemedText.SubHeader>No Live Campaigns</ThemedText.SubHeader>}
              {liveCampaigns && liveCampaigns?.length > 0 && (
                <>
                  <ThemedText.SubHeader>
                    Live Campaigns
                  </ThemedText.SubHeader>
                  <Flex>
                    {distributionData?.filter(data => !isFinishedCampaign(data.endTimestamp)).map((data, i) => {
                      return (
                        <Box key={i} style={{ margin: '5px' }}>
                          <ColumnFlex >
                            <Flex>

                              <AssetLogo
                                chainId={chainId}
                                address={data.rewardToken}
                                symbol={data.symbolRewardToken}
                                size={'32px'}
                                hideL2Icon={true}
                              />
                              <ColumnFlex>
                                <div>{data.amount.toLocaleString()} {data.symbolRewardToken}</div>
                                <div style={{ fontSize: '9px' }}>※total distribution amount</div>
                              </ColumnFlex>
                            </Flex>
                            <div style={{ margin: '5px' }}></div>
                            <div style={{ fontSize: '10px' }}>Start At: {new Date(data.startTimestamp * 1000).toLocaleString()}</div>
                            <div style={{ fontSize: '10px' }}>End At: {new Date(data.endTimestamp * 1000).toLocaleString()}</div>
                          </ColumnFlex>
                        </Box>
                      );
                    })}
                  </Flex>
                </>
              )}
            </ColumnFlex>
          </Box>
          <ThemedText.Link onClick={() => window.open(poolLink)} color={'white'}>
            <Flex>
              Get {lpTokenName}-LP
              <ExternalLinkIcon href={poolLink} color="white" />
            </Flex>
          </ThemedText.Link>
          <div style={{ fontSize: '10px' }}>
            <span>※ Even without staking LP tokens,</span>
            <span>you are eligible for rewards now.</span><br />
            <span>To claim all rewards, select 'Claim All Rewards</span>
          </div>
          <br />
          <ThemedText.Link onClick={() => window.open(
            `https://app.steer.finance/vault/${steerStakingAddress}`
          )} color={'white'}>
            <Flex>
              Manage on Steer
              <ExternalLinkIcon href={
                `https://app.steer.finance/vault/${steerStakingAddress}`
              } color="white" />
            </Flex>
          </ThemedText.Link>
        </ColumnFlex>
        <Box style={{ minWidth: "200px" }}>
          <ColumnFlex>
            <div>Rewards</div>
            {data && Object.entries(data).map(([key, value], i) => (
              <Flex style={{ padding: '5px', marginTop: '20px' }}>
                <AssetLogo
                  chainId={chainId}
                  address={key}
                  symbol={value.symbol ?? ''}
                  size={'32px'}
                  hideL2Icon={true}
                />
                <div>{value.unclaimed}</div>
                <div >{value?.symbol ?? ''}</div>
              </Flex>
            ))}
            {(!data || Object.entries(data).length === 0) && usersPosition?.length === 0 && <div>※No Rewards Available to claim, <br />Add liquidity to claim rewards</div>}
            {usersPosition && usersPosition?.length >= 1 && (
              <>
                <ColumnFlex>
                  <Flex>
                    <div>Your Liquidity</div>
                    <div>{calculateTotalLiquidity(usersPosition).toLocaleString()}</div>
                  </Flex>
                  <div style={{ fontSize: '10px', marginTop: '10px' }}>
                    Note: Due to the use of the Merkle tree for reward calculations,<br /> it may take around 8 hours for your reward amount to be displayed
                    <br />
                    Note: Only in-range liquidity is rewarded. Be cautious with liquidity that falls out of range.
                  </div>
                </ColumnFlex>
              </>)
            }
          </ColumnFlex>
        </Box>
      </ParentBox>
    </>
  )
}