import type {
  Actions,
  AddEthereumChainParameter,
  Provider,
  ProviderConnectInfo,
  ProviderRpcError,
} from '@web3-react/types'
import { Connector } from '@web3-react/types'

import type detectEthereumProvider from './detect'

type EnkryptProvider = Provider & {
  isEnkrypt?: boolean;
  isConnected?: () => boolean;
  providers?: EnkryptProvider[];
};

// eslint-disable-next-line import/no-unused-modules
export class NoEnkryptError extends Error {
  public constructor() {
    super("Enkrypt not installed");
    this.name = NoEnkryptError.name;
    Object.setPrototypeOf(this, NoEnkryptError.prototype);
  }
}

function parseChainId(chainId: string) {
  return Number.parseInt(chainId, 16)
}

/**
 * @param options - Options to pass to `@Enkrypt/detect-provider`
 * @param onError - Handler to report errors thrown from eventListeners.
 */
// eslint-disable-next-line import/no-unused-modules
export interface EnkryptConstructorArgs {
  actions: Actions;
  options?: Parameters<typeof detectEthereumProvider>[0];
  onError?: (error: Error) => void;
}

// eslint-disable-next-line import/no-unused-modules
export class Enkrypt extends Connector {
  /** {@inheritdoc Connector.provider} */
  public provider?: EnkryptProvider;

  private readonly options?: Parameters<typeof detectEthereumProvider>[0];
  private eagerConnection?: Promise<void>;

  constructor({ actions, options, onError }: EnkryptConstructorArgs) {
    super(actions, onError);
    this.options = options;
  }

  private async isomorphicInitialize(): Promise<void> {
    if (this.eagerConnection) return;

    return (this.eagerConnection = import("./detect").then(async (m) => {
      const provider = await m.default({
        mustBeEnkrypt: true,
        ...this.options,
      });
      if ((provider as unknown as EnkryptProvider).isEnkrypt) {
        this.provider = provider as unknown as EnkryptProvider;

        this.provider.on(
          "connect",
          ({ chainId }: ProviderConnectInfo): void => {
            this.actions.update({ chainId: parseChainId(chainId) });
          }
        );

        this.provider.on("disconnect", (error: ProviderRpcError): void => {
          this.actions.resetState();
          this.onError?.(error);
        });

        this.provider.on("chainChanged", (chainId: string): void => {
          this.actions.update({ chainId: parseChainId(chainId) });
        });

        this.provider.on("accountsChanged", (accounts: string[]): void => {
          if (accounts.length === 0) {
            // handle this edge case by disconnecting
            this.actions.resetState();
          } else {
            this.actions.update({ accounts });
          }
        });
      }
    }));
  }

  /** {@inheritdoc Connector.connectEagerly} */
  public async connectEagerly(): Promise<void> {
    const cancelActivation = this.actions.startActivation();

    await this.isomorphicInitialize();
    if (!this.provider) return cancelActivation();

    return Promise.all([
      this.provider.request({ method: "eth_chainId" }) as Promise<string>,
      this.provider.request({ method: "eth_accounts" }) as Promise<string[]>,
    ])
      .then(([chainId, accounts]) => {
        if (accounts.length) {
          this.actions.update({ chainId: parseChainId(chainId), accounts });
        } else {
          throw new Error("No accounts returned");
        }
      })
      .catch((error) => {
        console.debug("Could not connect eagerly", error);
        cancelActivation();
      });
  }

  /**
   * Initiates a connection.
   *
   * @param desiredChainIdOrChainParameters - If defined, indicates the desired chain to connect to. If the user is
   * already connected to this chain, no additional steps will be taken. Otherwise, the user will switch
   * to the chain.
   */
  public async activate(
    desiredChainIdOrChainParameters?: number | AddEthereumChainParameter
  ): Promise<void> {
    let cancelActivation: () => void;
    if (!this.provider?.isConnected?.())
      cancelActivation = this.actions.startActivation();

    return this.isomorphicInitialize()
      .then(async () => {
        if (!this.provider) throw new NoEnkryptError();

        return Promise.all([
          this.provider.request({ method: "eth_chainId" }) as Promise<string>,
          this.provider.request({ method: "eth_requestAccounts" }) as Promise<
            string[]
          >,
        ]).then(([chainId, accounts]) => {
          const receivedChainId = parseChainId(chainId);
          const desiredChainId =
            typeof desiredChainIdOrChainParameters === "number"
              ? desiredChainIdOrChainParameters
              : desiredChainIdOrChainParameters?.chainId;

          // if there's no desired chain, or it's equal to the received, update
          if (!desiredChainId || receivedChainId === desiredChainId)
            return this.actions.update({ chainId: receivedChainId, accounts });

          const desiredChainIdHex = `0x${desiredChainId.toString(16)}`;

          // if we're here, we can try to switch networks
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          return this.provider!.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: desiredChainIdHex }],
          })
            .catch((error: ProviderRpcError) => {
              if (
                error.code === 4902 &&
                typeof desiredChainIdOrChainParameters !== "number"
              ) {
                // if we're here, we can try to add a new network
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                return this.provider!.request({
                  method: "wallet_addEthereumChain",
                  params: [
                    {
                      ...desiredChainIdOrChainParameters,
                      chainId: desiredChainIdHex,
                    },
                  ],
                });
              }

              throw error;
            })
            .then(() => this.activate(desiredChainId));
        });
      })
      .catch((error) => {
        cancelActivation?.();
        throw error;
      });
  }
}
