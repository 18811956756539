import { Trans } from '@lingui/macro'
import { Price, Token } from '@uniswap/sdk-core'
import { priceToClosestTick } from '@uniswap/v3-sdk'
import { useWeb3React } from '@web3-react/core'
import SteerImage from 'assets/images/steer.svg'
import { ButtonGray } from 'components/Button'
import Card from 'components/Card'
import { AutoColumn } from 'components/Column'
import QuestionHelper from 'components/QuestionHelper'
import { RowBetween, RowFixed } from 'components/Row'
import usePrevious from 'hooks/usePrevious'
import { SteerVault } from 'hooks/useSteerData'
import { DynamicSection } from 'pages/AddLiquidity/styled'
import { useEffect, useMemo, useState } from 'react'
import { useStakingApr } from 'state/info/hooks'
import { LiquidityManagement } from 'state/mint/v3/actions'
import styled, { keyframes } from 'styled-components/macro'
import { ThemedText } from 'theme'

import { RangeOption } from './RangeOption'

export interface IPresetArgs {
  liquidityManagement: LiquidityManagement
  lowerTick: number
  upperTick: number
  address?: string
  title?: string
  apr?: number
  tokenStr?: string
}

interface PresetSelectorParams {
  preset: IPresetArgs | undefined
  handlePresetRangeSelection: (preset: IPresetArgs) => void
  price: Price<Token, Token> | undefined
  liquidityManagement: LiquidityManagement
  steerPairs: SteerVault[]
  disabled?: boolean
}

export const PresetImages: Record<LiquidityManagement, string> = {
  [LiquidityManagement.STEER]: SteerImage,
  [LiquidityManagement.MANUAL]: '',
}

const pulse = (color: string) => keyframes`
  0% {
    box-shadow: 0 0 0 0 ${color};
  }

  70% {
    box-shadow: 0 0 0 2px ${color};
  }

  100% {
    box-shadow: 0 0 0 0 ${color};
  }
`
const FocusedOutlineCard = styled(Card)<{ pulsing: boolean }>`
  border: 1px solid ${({ theme }) => theme.backgroundInteractive};
  animation: ${({ pulsing, theme }) => pulsing && pulse(theme.accentAction)} 0.6s linear;
  align-self: center;
`

const Select = styled.div<{ show?: boolean }>`
  align-items: flex-start;
  display: ${({ show }) => (show ? 'grid' : 'none')};
  grid-auto-flow: column;
  grid-gap: 8px;
`

const Wrapper = styled.div<{ disabled?: boolean }>`
  display: ${({ disabled }) => (disabled ? 'none' : 'block')};
`

export default function PresetSelector({
  preset,
  liquidityManagement,
  handlePresetRangeSelection,
  price,
  steerPairs,
  disabled,
}: PresetSelectorParams) {
  const { chainId } = useWeb3React()

  const currentTick = price ? priceToClosestTick(price) : undefined
  const ranges: IPresetArgs[] = useMemo(() => {
    return steerPairs && currentTick
      ? steerPairs.map((pair) => {
          const lowerTick = Number(pair.lowerTick ?? 0)
          const upperTick = Number(pair.upperTick ?? 0)
          const apr = pair.apr
          const positionWidthPercent = calculatePositionWidth(currentTick, upperTick, lowerTick)
          const pairStrategyName = pair?.strategyName
          const isInRange = lowerTick < currentTick && currentTick < upperTick
          const pairTypeTitle = isInRange
            ? pairStrategyName.toLowerCase().includes('stable')
              ? 'Stable'
              : percentageToMultiplier(positionWidthPercent) > 1.2
              ? 'Wide'
              : 'Narrow'
            : 'Out of Range'
          return {
            liquidityManagement: LiquidityManagement.STEER,
            title: pairTypeTitle,
            address: pair.address,
            lowerTick,
            upperTick,
            apr,
            tokenStr: pair.token0Address + '-' + pair.token1Address,
          }
        })
      : []
  }, [steerPairs, currentTick])
  const rewardApr = useStakingApr(chainId, preset?.address?.toLocaleLowerCase())
  useEffect(() => {
    if (ranges.length && liquidityManagement !== LiquidityManagement.MANUAL) {
      handlePresetRangeSelection(ranges[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ranges, liquidityManagement])

  const [showOptions, setShowOptions] = useState(false)
  const [pulsing, setPulsing] = useState(false)

  const previousPreset = usePrevious(preset)

  useEffect(() => {
    if (preset && previousPreset !== preset) {
      setPulsing(true)
    }
  }, [previousPreset, preset])

  const apr = useMemo(() => (preset?.apr ?? 0) + (rewardApr ?? 0), [preset?.apr, rewardApr])

  return (
    <Wrapper disabled={disabled}>
      <AutoColumn gap="16px">
        <DynamicSection gap="md">
          <FocusedOutlineCard pulsing={pulsing} onAnimationEnd={() => setPulsing(false)}>
            <RowBetween>
              <AutoColumn>
                {preset?.liquidityManagement && (
                  <img src={PresetImages[preset.liquidityManagement]} alt={preset.liquidityManagement} />
                )}
                <RowFixed>
                  <ThemedText.DeprecatedLabel>
                    {apr > 0 && <Trans> APR: {apr.toFixed(2)}%</Trans>}
                  </ThemedText.DeprecatedLabel>
                  {(rewardApr ?? 0) > 0 && (
                    <QuestionHelper
                      text={
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <div>
                            <Trans>Fee APR: {(preset?.apr ?? 0).toFixed(2)}%</Trans>
                          </div>
                          <div>
                            <Trans>Staking APR: {(rewardApr ?? 0).toFixed(2)}%</Trans>
                          </div>
                        </div>
                      }
                    />
                  )}
                </RowFixed>
              </AutoColumn>

              <ButtonGray onClick={() => setShowOptions(!showOptions)} width="auto" padding="4px" $borderRadius="6px">
                {showOptions ? <Trans>Hide</Trans> : <Trans>Edit</Trans>}
              </ButtonGray>
            </RowBetween>
          </FocusedOutlineCard>

          <Select show={showOptions}>
            {ranges.map((range, i) => {
              return (
                <RangeOption
                  range={range}
                  active={preset === range}
                  onClick={() => handlePresetRangeSelection(range)}
                  key={i}
                />
              )
            })}
          </Select>
        </DynamicSection>
      </AutoColumn>
    </Wrapper>
  )
}

export const calculatePositionWidth = (currentTick: number, upperTick: number, lowerTick: number): number => {
  const currentPrice = Math.pow(1.0001, Number(currentTick))
  const upperPrice = Math.pow(1.0001, Number(upperTick))
  const lowerPrice = Math.pow(1.0001, Number(lowerTick))

  // Calculate upper and lower bounds width in percentage
  const upperBoundWidthPercent = ((upperPrice - currentPrice) / currentPrice) * 100
  const lowerBoundWidthPercent = ((currentPrice - lowerPrice) / currentPrice) * 100

  // Calculate average width of the position
  const positionWidthPercent = (upperBoundWidthPercent + lowerBoundWidthPercent) / 2

  return positionWidthPercent
}

export const percentageToMultiplier = (percentage: number): number => {
  const multiplier = 1 + percentage / 100
  return multiplier
}
