import { Trans } from '@lingui/macro';
import { useWeb3React } from '@web3-react/core';
import { ButtonEmphasis, ButtonSize, ThemeButton } from 'components/Button';
import { AutoColumn } from 'components/Column';
import QuestionHelper from 'components/QuestionHelper'
import { Farm } from 'components/Farm/FarmWithMerkl';
import { RowBetween, RowFixed } from 'components/Row';
import { SupportedChainId, isAstarEVMChain, isSupportedChain } from 'constants/chains';
import React, { useMemo } from 'react';
import { useV2Tvl, useV2TxVolume, useV3Tvl, useV3TxVolume } from 'state/info/hooks';
import styled from 'styled-components';
import { ThemedText } from 'theme';
import { ExternalLinkIcon } from 'theme/components';
import { useFetchCampaignData } from 'hooks/useHooksMerklCampaigns';
import {ClaimsComponent} from 'components/Farm/ClaimRewardWithMerkl';
import { useMerklData } from 'hooks/useMerkleData';

const PageWrapper = styled(AutoColumn)`
  padding: 68px 8px 0px;
  max-width: 870px;
  width: 100%;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    max-width: 800px;
  `};

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    max-width: 500px;
  `};

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    padding-top: 48px;
  }

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    padding-top: 20px;
  }
`

const ControlContainer = styled.div`
  padding-bottom: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.backgroundOutline};
`

const ValuationContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 24px;
  row-gap: 12px;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    column-gap: 48px;
  `};
`

const TitleRow = styled(RowBetween)`
  color: ${({ theme }) => theme.textSecondary};
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
  `};
`

const HeaderButton = styled(ThemeButton)`
  border-color: transparent;
  border-radius: 12px;
  border-style: solid;
  border-width: 1px;
  height: 40px;
  margin-top: 8px;
`

const StakingPools: React.FC = () => {
  const { chainId, account } = useWeb3React()
  const chainIdMemo = useMemo(() => chainId, [chainId]);
  // const { data, isLoading } = useFetchCampaignData(chainIdMemo?.toString() ?? '592')
  const { data,isLoading } = useMerklData(chainIdMemo, account);
  console.log('data', data)
  const v3Tvl = useV3Tvl()
  const v2Tvl = useV2Tvl()
  const v3TxVolume = useV3TxVolume()
  const v2TxVolume = useV2TxVolume()

  const tvlV3 = useMemo(() => {
    return v3Tvl[chainIdMemo ?? -1] ?? 0
  }, [v3Tvl, chainIdMemo])

  const tvlV2 = useMemo(() => {
    return v2Tvl[chainIdMemo ?? -1] ?? 0
  }, [v2Tvl, chainIdMemo])

  const tvl = useMemo(() => {
    return tvlV3 + tvlV2
  }, [tvlV3, tvlV2])

  const txVolumeV3 = useMemo(() => {
    return v3TxVolume[chainIdMemo ?? -1] ?? 0
  }, [v3TxVolume, chainIdMemo])

  const txVolumeV2 = useMemo(() => {
    return v2TxVolume[chainIdMemo ?? -1] ?? 0
  }, [v2TxVolume, chainIdMemo])

  const txVolume = useMemo(() => {
    return txVolumeV3 + txVolumeV2
  }, [txVolumeV3, txVolumeV2])

  if (!chainIdMemo || !isSupportedChain(chainIdMemo)) {
    return (
      <PageWrapper>
        <div>
          <h1>Farms</h1>
          <p>Connect your wallet or switch your network</p>
        </div>
      </PageWrapper>
    );
  }

  if (isLoading) {
    return (
      <PageWrapper>
        <div>
          <h1>Farms</h1>
          <p>Loading...</p>
        </div>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <AutoColumn gap="lg" justify="center">
        <AutoColumn gap="lg" style={{ width: "100%" }}>
          {tvl !== 0 && (
            <ControlContainer>
              <ValuationContainer>
                <div>
                  <ThemedText.LabelSmall>
                    <Trans>TVL</Trans>
                  </ThemedText.LabelSmall>
                  <RowFixed>
                    <ThemedText.HeadlineMedium>
                      $
                      {tvl &&
                        tvl.toLocaleString("en-US", {
                          maximumFractionDigits: 0,
                        })}
                    </ThemedText.HeadlineMedium>
                    {tvlV2 !== 0 && (
                      <QuestionHelper
                        text={
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div><Trans>V2 TVL: ${tvlV2.toLocaleString('en-US', { maximumFractionDigits: 0 })}</Trans></div>
                            <div><Trans>V3 TVL: ${tvlV3.toLocaleString('en-US', { maximumFractionDigits: 0 })}</Trans></div>
                          </div>
                        }
                      />
                    )}
                  </RowFixed>
                </div>
                <div>
                  <ThemedText.LabelSmall>
                    <Trans>Volume 24H</Trans>
                  </ThemedText.LabelSmall>
                  <RowFixed>
                    <ThemedText.HeadlineMedium>
                      $
                      {txVolume &&
                        txVolume.toLocaleString("en-US", {
                          maximumFractionDigits: 0,
                        })}
                    </ThemedText.HeadlineMedium>
                    {tvlV2 !== 0 && (
                      <QuestionHelper
                        text={
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div><Trans>V2 Volume: ${txVolumeV2.toLocaleString('en-US', { maximumFractionDigits: 0 })}</Trans></div>
                            <div><Trans>V3 Volume: ${txVolumeV3.toLocaleString('en-US', { maximumFractionDigits: 0 })}</Trans></div>
                          </div>
                        }
                      />
                    )}
                  </RowFixed>
                </div>
              </ValuationContainer>
            </ControlContainer>
          )}
          {chainIdMemo === SupportedChainId.ASTR && (
            <>
              <TitleRow>
                <ThemedText.LargeHeader fontSize={30}>
                  <Trans>V2 Farms</Trans>
                </ThemedText.LargeHeader>
                <HeaderButton
                  size={ButtonSize.medium}
                  emphasis={ButtonEmphasis.medium}
                  onClick={() => {
                    window.open("https://v2.arthswap.org/#/farms");
                  }}
                  style={{ fontSize: "14px", padding: "15px" }}
                >
                  Use V2 Farms{" "}
                  <ExternalLinkIcon
                    href={`https://v2.arthswap.org/#/farms`}
                    color="white"
                  />
                </HeaderButton>
              </TitleRow>
            </>
          )}
          {isAstarEVMChain(chainIdMemo) && (
            <ThemedText.LargeHeader fontSize={30}>
              <Trans>V3 Farms</Trans>
            </ThemedText.LargeHeader>
          )}
          {<ClaimsComponent />}
          {data && data.length >= 1 ? (
            <div>
              {data.map((item, i) => {
                return (
                  <Farm
                    tokenA={{
                      address: item.token0 || undefined,
                      symbol: item.symbolToken0 || undefined,
                    }}
                    tokenB={{
                      address: item.token1 || undefined,
                      symbol: item.symbolToken1 || undefined,
                    } }
                    poolFee={item.poolFee}
                    tvl={item?.tvl}
                    apr={item?.meanAPR}
                    distributionData={item?.distributionData}
                    mainParameter={item.pool || undefined}
                    account={account || undefined}
                    rewardToken={undefined}
                    chainId={chainIdMemo}
                    key={i}
                    usersPositions={item?.userPositions} 
                  />
                )
              })}
            </div>
          ) : (
            <div>
              <p>No Farming or Switch Your Network</p>
            </div>
          )}
        </AutoColumn>
      </AutoColumn>
    </PageWrapper>
  );
};

export default StakingPools;
