import { useMemo } from 'react'
import { useSteerVaults } from 'state/info/hooks'

export const useFeeAprOfStakingTokenAddress = (chainId?: number, stakingTokenAddress?: string) => {
  const vaults = useSteerVaults()
  const baseFeeApr = useMemo(() => {
    if (!chainId) return
    return vaults[chainId]?.find((x) => x.address.toLocaleLowerCase() === stakingTokenAddress?.toLocaleLowerCase())
  }, [chainId, vaults, stakingTokenAddress])
  return (baseFeeApr?.apr || 0) / 100
}

export const useFeeAprOfByTokenAddress = (chainId?: number, tokenA?: string, tokenB?: string, feeTier?: string) => {
  const vaults = useSteerVaults()
  const baseFeeApr = useMemo(() => {
    if (!chainId) return
    return vaults[chainId]?.find((x) =>
      (x?.token0Address?.toLocaleLowerCase() === tokenA?.toLocaleLowerCase() && x?.token1Address?.toLocaleLowerCase() === tokenB?.toLocaleLowerCase()) &&
      feeTier === x?.feeTier
    )
  }, [chainId, vaults, tokenA, tokenB])
  return {
    feeApr: (baseFeeApr?.apr || 0) / 100,
    stakingTokenAddress: baseFeeApr?.address
  }
}