import { parseUnits } from "ethers/lib/utils";
import { CurrencyAmount, Token } from '@uniswap/sdk-core';
import { useMemo } from "react";

interface TokenAmountProps {
  chainId?: number;
  tokenAddress?: string;
  decimals?: number;
}

export const useTokenAmount = ({ chainId, tokenAddress, decimals }: TokenAmountProps) => {
  const tokenAmount = useMemo(() => {
    if (!chainId || !tokenAddress || !decimals) {
      return;
    }
    const tokenInstance = new Token(chainId, tokenAddress, decimals);
    const tokenValue = parseUnits('1', decimals).toString();
    return CurrencyAmount.fromRawAmount(tokenInstance, tokenValue);
  }, [chainId, tokenAddress, decimals]);

  return tokenAmount;
};