import { Trans } from '@lingui/macro'
import { CurrencyAmount, Percent, Token } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { SteerPositionWithUserBalances } from 'hooks/useSteerData'
import { ReactNode, useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'

import { AppState } from '../../types'
import { selectPercent } from './actions'

export function useBurnSteerState(): AppState['burnSteer'] {
  return useAppSelector((state) => state.burnSteer)
}

export function useDerivedSteerBurnInfo(position?: SteerPositionWithUserBalances): {
  liquidityPercentage?: Percent
  liquidityValue0?: CurrencyAmount<Token>
  liquidityValue1?: CurrencyAmount<Token>
  outOfRange: boolean
  error?: ReactNode
} {
  const { account } = useWeb3React()
  const { percent } = useBurnSteerState()

  const token0 = position?.currency0
  const token1 = position?.currency1

  const liquidityPercentage = new Percent(percent, 100)

  const discountedAmount0 = position?.token0BalanceWallet
    ? liquidityPercentage.multiply(position.token0BalanceWallet).quotient
    : undefined
  const discountedAmount1 = position?.token1BalanceWallet
    ? liquidityPercentage.multiply(position.token1BalanceWallet).quotient
    : undefined

  const liquidityValue0 =
    token0 && discountedAmount0 ? CurrencyAmount.fromRawAmount(token0, discountedAmount0) : undefined
  const liquidityValue1 =
    token1 && discountedAmount1 ? CurrencyAmount.fromRawAmount(token1, discountedAmount1) : undefined

  const outOfRange =
    position?.tick && position?.lowerTick && position?.upperTick
      ? Number(position.tick) < Number(position.lowerTick) || Number(position.tick) > Number(position.upperTick)
      : false

  let error: ReactNode | undefined
  if (!account) {
    error = <Trans>Connect Wallet</Trans>
  }
  if (percent === 0) {
    error = error ?? <Trans>Enter a percent</Trans>
  }
  return {
    liquidityPercentage,
    liquidityValue0,
    liquidityValue1,
    outOfRange,
    error,
  }
}

export function useBurnSteerActionHandlers(): {
  onPercentSelect: (percent: number) => void
} {
  const dispatch = useAppDispatch()

  const onPercentSelect = useCallback(
    (percent: number) => {
      dispatch(selectPercent({ percent }))
    },
    [dispatch]
  )

  return {
    onPercentSelect,
  }
}
