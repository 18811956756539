const trackEvent = (eventName: string, eventParams: any = {}) => {
  try {
    window.rudderanalytics.track(eventName, {
      ...eventParams,
      dapp: 'Arthswap-v3',
    })
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Error Logging rudderstack ', e)
  }
}

// eslint-disable-next-line import/no-unused-modules
export default trackEvent
