import { useState, useEffect } from 'react';

// Interface for Reward Tokens
interface RewardToken {
  decimals: number;
  minimumAmountPerEpoch: number;
  symbol: string;
  token: string;
}

// Interface for individual positions in each pool
interface PoolPosition {
  balance0: number;
  balance1: number;
  id: string;
  inRangeLiquidity: number;
  lowerTick: number;
  totalLiquidity: number;
  tvl: number;
  upperTick: number;
}

// Interface for ALM data (one of the sub-pools)
interface AlmData {
  almAPR: number;
  almAddress: string;
  almBalance0: number;
  almBalance1: number;
  almInRangeLiquidity: number;
  almTVL: number;
  almTotalLiquidity: number;
  forwarderType: number;
  label: string;
  origin: number;
  positions: PoolPosition[];
  priority: number;
  sender: string;
  target: string;
  totalSupply: number;
  type: number;
}

// Interface for Pool information
interface Pool {
  amm: number;
  ammAlgo: number;
  ammAlgoName: string;
  ammName: string;
  aprs: Record<string, unknown>;
  chainId: number;
  decimalsToken0: number;
  decimalsToken1: number;
  disputeLive: boolean;
  distributionData: Distribution[];
  endOfDisputePeriod: number;
  meanAPR: number;
  pool: string;
  poolBalanceToken0: number;
  poolBalanceToken1: number;
  poolFee: number;
  poolTotalLiquidity: number;
  rewardsPerToken: Record<string, unknown>;
  symbolToken0: string;
  symbolToken1: string;
  tick: number;
  token0: string;
  token1: string;
  tvl: number;
  userPositions?: PoolPosition[] | null;
  userTotalLiquidity: number;
  userInRangeLiquidity: number;
  userTVL: number;
  userBalanceToken0: number;
  userBalanceToken1: number;
  alm: Record<string, AlmData>;
}

// Interface for Distribution Data
interface Distribution {
  amount: number;
  apr: number;
  blacklist: string[];
  blacklistedBalance0: number;
  blacklistedBalance1: number;
  blacklistedLiquidity: number;
  breakdown: Record<string, unknown>;
  decimalsRewardToken: number;
  endTimestamp: number;
  id: string;
  isBoosted: boolean;
  isLive: boolean;
  isMock: boolean;
  isOutOfRangeIncentivized: boolean;
  propFees: number;
  propToken0: number;
  propToken1: number;
  rewardToken: string;
  startTimestamp: number;
  symbolRewardToken: string;
  unclaimed: number;
  whitelist: string[];
}

// Interface for the entire Merkl data structure
interface MerklData {
  merkleRoot: string;
  message: string;
  validRewardTokens: RewardToken[];
  pools: Record<string, Pool>;
}

// Interface to represent data returned from the API
interface ChainData {
  [chainId: number]: MerklData;
}

export type {
  RewardToken,
  PoolPosition,
  AlmData,
  Pool,
  Distribution,
  MerklData,
  ChainData
};


// Custom hook to fetch Merkl data
export function useMerklData(chainId?: number, account?: string | null) {
  const [data, setData] = useState<Pool[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function fetchMerklData() {
      if (!chainId) return;

      const baseUrl = 'https://api.merkl.xyz/v3/merkl';
      const url = account
        ? `${baseUrl}?chainIds=${chainId}&user=${account}`
        : `${baseUrl}?chainIds=${chainId}`;

      try {
        const response = await fetch(url);

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result: ChainData = await response.json();
        const poolInfo = Object.values(result[chainId]?.pools || {}).filter(pool => pool.ammName === 'ARTHSWAP');

        setData(poolInfo);
      } catch (err: any) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    }

    fetchMerklData();
  }, [chainId, account]);

  return { data, isLoading, error };
}
