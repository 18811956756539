import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import { ButtonRadioChecked } from 'components/Button'
import { AutoColumn } from 'components/Column'
import { useFarmApr } from 'hooks/useFarmApr'
import React, { useMemo } from 'react'
import { useStakingApr, useSteerStakingPools } from 'state/info/hooks'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

import { IPresetArgs, PresetImages } from '.'

const ResponsiveText = styled(ThemedText.DeprecatedLabel)`
  line-height: 16px;
  font-size: 14px;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    font-size: 12px;
    line-height: 12px;
  `};
`

const ResponsiveTextSmall = styled(ThemedText.DeprecatedDarkGray)`
  line-height: 12px;
  font-size: 12px;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    font-size: 10px;
    line-height: 10px;
  `};
`

interface RangeOptionProps {
  range: IPresetArgs
  active: boolean
  onClick: () => void
}

export function RangeOption({ range, active, onClick }: RangeOptionProps) {
  const { chainId } = useWeb3React()
  const steerStakingPools = useSteerStakingPools(chainId)
  const matchedSteerStakingPool = steerStakingPools?.find(
    (pool) => pool.stakingToken?.toLocaleLowerCase() === range.address
  )
  useFarmApr({
    tokenA: matchedSteerStakingPool?.rewardTokenADetail,
    tokenB: matchedSteerStakingPool?.rewardTokenBDetail,
    stakingTokenAddress: matchedSteerStakingPool?.stakingToken,
    chainId,
    dailyEmissionRewardA: matchedSteerStakingPool?.dailyEmissionRewardA,
    dailyEmissionRewardB: matchedSteerStakingPool?.dailyEmissionRewardB,
    stakingPool: matchedSteerStakingPool?.stakingPool,
  })

  const rewardApr = useStakingApr(chainId, range.address?.toLocaleLowerCase())
  const apr = useMemo(() => (range.apr ?? 0) + (rewardApr ?? 0), [range.apr, rewardApr])
  return (
    <ButtonRadioChecked active={active} onClick={onClick}>
      <AutoColumn gap="sm" justify="flex-start">
        <AutoColumn justify="flex-start" gap="6px">
          {range.liquidityManagement && (
            <img src={PresetImages[range.liquidityManagement]} alt={range.liquidityManagement} />
          )}
          <ResponsiveText>
            <Trans>{range.title}</Trans>
          </ResponsiveText>
          {apr > 0 && (
            <ResponsiveTextSmall>
              <Trans>APR: {apr.toFixed(2)}%</Trans>
            </ResponsiveTextSmall>
          )}
        </AutoColumn>
      </AutoColumn>
    </ButtonRadioChecked>
  )
}
