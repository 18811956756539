import { BigNumber } from '@ethersproject/bignumber'
import type { TransactionResponse } from '@ethersproject/providers'
import { Trans } from '@lingui/macro'
import { TraceEvent } from '@uniswap/analytics'
import { BrowserEvent, InterfaceElementName, InterfaceEventName } from '@uniswap/analytics-events'
import { Currency, CurrencyAmount, Percent, Token } from '@uniswap/sdk-core'
import { FeeAmount, NonfungiblePositionManager, tickToPrice } from '@uniswap/v3-sdk'
import { useWeb3React } from '@web3-react/core'
import { useToggleAccountDrawer } from 'components/AccountDrawer'
import OwnershipWarning from 'components/addLiquidity/OwnershipWarning'
import { sendEvent } from 'components/analytics'
import PresetSelector, { IPresetArgs } from 'components/PresetSelector'
import { SteerPositionPreview } from 'components/SteerPositionPreview'
import UnsupportedCurrencyFooter from 'components/swap/UnsupportedCurrencyFooter'
import { ToggleElement, ToggleWrapper } from 'components/Toggle/MultiToggle'
import { useGetConnection } from 'connection'
import { TRANSACTION_FAILED, TRANSACTION_INITIATED, TRANSACTION_SUCCESS } from 'constants/analytics'
import usePrevious from 'hooks/usePrevious'
import { SteerVaultState, useSteerPosition } from 'hooks/useSteerData'
import JSBI from 'jsbi'
import { useSingleCallResult } from 'lib/hooks/multicall'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { AlertTriangle } from 'react-feather'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Text } from 'rebass'
import { useSteerVaults } from 'state/info/hooks'
import {
  useRangeHopCallbacks,
  useV3DerivedMintInfo,
  useV3MintActionHandlers,
  useV3MintState,
} from 'state/mint/v3/hooks'
import { useTheme } from 'styled-components/macro'
import { addressesAreEquivalent } from 'utils/addressesAreEquivalent'
import trackEvent from 'utils/analytics'

import { ButtonError, ButtonLight, ButtonPrimary, ButtonText } from '../../components/Button'
import { BlueCard, OutlineCard, YellowCard } from '../../components/Card'
import { AutoColumn } from '../../components/Column'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import FeeSelector from '../../components/FeeSelector'
import HoverInlineText from '../../components/HoverInlineText'
import LiquidityChartRangeInput from '../../components/LiquidityChartRangeInput'
import { AddRemoveTabs } from '../../components/NavigationTabs'
import { PositionPreview } from '../../components/PositionPreview'
import RangeSelector from '../../components/RangeSelector'
import PresetsButtons from '../../components/RangeSelector/PresetsButtons'
import RateToggle from '../../components/RateToggle'
import Row, { AutoRow, RowBetween, RowFixed } from '../../components/Row'
import { SwitchLocaleLink } from '../../components/SwitchLocaleLink'
import TransactionConfirmationModal, { ConfirmationModalContent } from '../../components/TransactionConfirmationModal'
import { NONFUNGIBLE_POSITION_MANAGER_ADDRESSES } from '../../constants/addresses'
import { ZERO_PERCENT } from '../../constants/misc'
import { nativeOnChain, WRAPPED_NATIVE_CURRENCY } from '../../constants/tokens'
import { useCurrency } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import { useSteerPeripheryContract, useV3NFTPositionManagerContract, useWETHContract } from '../../hooks/useContract'
import { useDerivedPositionInfo } from '../../hooks/useDerivedPositionInfo'
import { useIsSwapUnsupported } from '../../hooks/useIsSwapUnsupported'
import { useStablecoinValue } from '../../hooks/useStablecoinPrice'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import { useV3PositionFromTokenId } from '../../hooks/useV3Positions'
import { Bound, Field, LiquidityManagement } from '../../state/mint/v3/actions'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { TransactionType } from '../../state/transactions/types'
import { useIsExpertMode, useUserSlippageToleranceWithDefault } from '../../state/user/hooks'
import { ThemedText } from '../../theme'
import { calculateGasMargin } from '../../utils/calculateGasMargin'
import { currencyId } from '../../utils/currencyId'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { Dots } from '../Pool/styleds'
import { Review } from './Review'
import {
  CurrencyDropdown,
  DynamicSection,
  HideMedium,
  MediumOnly,
  PageWrapper,
  ResponsiveTwoColumns,
  RightContainer,
  ScrollablePage,
  StackedContainer,
  StackedItem,
  StyledInput,
  Wrapper,
} from './styled'

const DEFAULT_ADD_IN_RANGE_SLIPPAGE_TOLERANCE = new Percent(50, 10_000)

export default function AddLiquidity() {
  const navigate = useNavigate()
  const {
    currencyIdA,
    currencyIdB,
    feeAmount: feeAmountFromUrl,
    tokenId,
    vaultAddress,
  } = useParams<{
    currencyIdA?: string
    currencyIdB?: string
    feeAmount?: string
    tokenId?: string
    vaultAddress?: string
  }>()
  const { account, chainId, provider, connector } = useWeb3React()
  const theme = useTheme()

  const toggleWalletDrawer = useToggleAccountDrawer() // toggle wallet when disconnected
  const expertMode = useIsExpertMode()
  const addTransaction = useTransactionAdder()
  const positionManager = useV3NFTPositionManagerContract()
  const getConnection = useGetConnection()
  const connection = getConnection(connector)

  // check for existing position if tokenId in url
  const { position: existingPositionDetails, loading: positionLoading } = useV3PositionFromTokenId(
    tokenId ? BigNumber.from(tokenId) : undefined
  )
  const { data: existingSteerPosition, loading: steerPositionLoading } = useSteerPosition(vaultAddress)
  const hasExistingPosition = !!existingPositionDetails && !positionLoading
  const hasExistingSteerPosition = !!existingSteerPosition && !steerPositionLoading
  const { position: existingPosition } = useDerivedPositionInfo(existingPositionDetails)

  // fee selection from url
  const feeAmount: FeeAmount | undefined =
    feeAmountFromUrl && Object.values(FeeAmount).includes(parseFloat(feeAmountFromUrl))
      ? parseFloat(feeAmountFromUrl)
      : undefined

  const baseCurrency = useCurrency(currencyIdA)
  const currencyB = useCurrency(currencyIdB)
  // prevent an error if they input ETH/WETH
  const quoteCurrency =
    baseCurrency && currencyB && baseCurrency.wrapped.equals(currencyB.wrapped) ? undefined : currencyB

  // mint state
  const { independentField, typedValue, startPriceTypedValue, liquidityRangeType, presetRange } = useV3MintState()

  const {
    pool,
    ticks,
    dependentField,
    price,
    pricesAtTicks,
    pricesAtLimit,
    parsedAmounts,
    currencyBalances,
    position,
    noLiquidity,
    currencies,
    errorMessage,
    invalidPool,
    invalidRange,
    outOfRange,
    depositADisabled,
    depositBDisabled,
    invertPrice,
    ticksAtLimit,
    wnativeBalance,
  } = useV3DerivedMintInfo(
    baseCurrency ?? undefined,
    quoteCurrency ?? undefined,
    feeAmount,
    baseCurrency ?? undefined,
    existingPosition
  )

  const {
    onFieldAInput,
    onFieldBInput,
    onLeftRangeInput,
    onRightRangeInput,
    onStartPriceInput,
    onChangeLiquidityRangeType,
    onChangePresetRange,
  } = useV3MintActionHandlers(noLiquidity)

  const isValid = !errorMessage && !invalidRange

  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false) // clicked confirm

  // txn values
  const deadline = useTransactionDeadline() // custom from users settings

  const [txHash, setTxHash] = useState<string>('')

  // get formatted amounts
  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: parsedAmounts[dependentField]?.toSignificant(6) ?? '',
  }

  const usdcValues = {
    [Field.CURRENCY_A]: useStablecoinValue(parsedAmounts[Field.CURRENCY_A]),
    [Field.CURRENCY_B]: useStablecoinValue(parsedAmounts[Field.CURRENCY_B]),
  }

  // get the max amounts user can add
  const maxAmounts: { [field in Field]?: CurrencyAmount<Currency> } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmountSpend(currencyBalances[field]),
      }
    },
    {}
  )

  const atMaxAmounts: { [field in Field]?: CurrencyAmount<Currency> } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmounts[field]?.equalTo(parsedAmounts[field] ?? '0'),
      }
    },
    {}
  )

  const amountA = parsedAmounts[Field.CURRENCY_A]
  const amountB = parsedAmounts[Field.CURRENCY_B]

  const [wrappingNative, setWrappingNative] = useState(false)
  const amountToWrap = useMemo(() => {
    if (
      !baseCurrency ||
      !quoteCurrency ||
      !amountA ||
      !amountB ||
      !chainId ||
      liquidityRangeType !== LiquidityManagement.STEER
    )
      return
    if (baseCurrency.isNative) {
      if (wnativeBalance && JSBI.greaterThan(amountA.numerator, wnativeBalance.numerator)) {
        return JSBI.subtract(amountA.numerator, wnativeBalance.numerator)
      }
      return
    } else if (quoteCurrency.isNative) {
      if (wnativeBalance && JSBI.greaterThan(amountB.numerator, wnativeBalance.numerator)) {
        return JSBI.subtract(amountB.numerator, wnativeBalance.numerator)
      }
      return
    }
    return
  }, [amountA, amountB, baseCurrency, chainId, liquidityRangeType, quoteCurrency, wnativeBalance])

  useEffect(() => {
    if (wrappingNative && !amountToWrap) {
      setWrappingNative(false)
    }
  }, [amountToWrap, wrappingNative])

  const steerPeripheryContract = useSteerPeripheryContract()

  // check whether the user has approved the router on the tokens
  const [approvalA, approveACallback] = useApproveCallback(
    parsedAmounts[Field.CURRENCY_A],
    chainId
      ? liquidityRangeType === LiquidityManagement.STEER
        ? steerPeripheryContract?.address
        : NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[chainId]
      : undefined
  )
  const [approvalB, approveBCallback] = useApproveCallback(
    parsedAmounts[Field.CURRENCY_B],
    chainId
      ? liquidityRangeType === LiquidityManagement.STEER
        ? steerPeripheryContract?.address
        : NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[chainId]
      : undefined
  )

  const allowedSlippage = useUserSlippageToleranceWithDefault(
    outOfRange ? ZERO_PERCENT : DEFAULT_ADD_IN_RANGE_SLIPPAGE_TOLERANCE
  )

  const wnativeContract = useWETHContract()

  async function onWrap() {
    if (!chainId || !provider || !account || !wnativeContract || !amountToWrap) return
    setWrappingNative(true)
    try {
      const wrapEstimateGas = await wnativeContract.estimateGas.deposit({
        value: `0x${amountToWrap.toString(16)}`,
      })
      const wrapResponse: TransactionResponse = await wnativeContract.deposit({
        gasLimit: calculateGasMargin(wrapEstimateGas),
        value: `0x${amountToWrap.toString(16)}`,
      })
      setAttemptingTxn(false)
      addTransaction(wrapResponse, {
        type: TransactionType.WRAP,
        currencyAmountRaw: amountToWrap.toString(),
        unwrapped: false,
        chainId,
      })
    } catch (e) {
      console.error(e)
      setWrappingNative(false)
    }
  }

  async function onAdd() {
    if (!chainId || !provider || !account) return

    if (!baseCurrency || !quoteCurrency) {
      return
    }

    if (liquidityRangeType === LiquidityManagement.STEER) {
      if (!steerPeripheryContract || !presetRange || !presetRange.tokenStr) return
      const baseCurrencyAddress = baseCurrency.wrapped ? baseCurrency.wrapped.address.toLowerCase() : undefined
      const quoteCurrencyAddress = quoteCurrency.wrapped ? quoteCurrency.wrapped.address.toLowerCase() : undefined
      const steerToken0Address = presetRange.tokenStr.split('-')[0]
      if (!amountA || !amountB || !presetRange.address || !baseCurrencyAddress || !quoteCurrencyAddress) return
      setAttemptingTxn(true)
      try {
        const vaultAddress = presetRange.address
        const estimatedGas = await steerPeripheryContract.estimateGas.deposit(
          vaultAddress,
          (steerToken0Address.toLowerCase() === baseCurrencyAddress ? amountA : amountB).numerator.toString(),
          (steerToken0Address.toLowerCase() === baseCurrencyAddress ? amountB : amountA).numerator.toString(),
          0,
          0,
          account
        )
        const response: TransactionResponse = await steerPeripheryContract.deposit(
          vaultAddress,
          (steerToken0Address.toLowerCase() === baseCurrencyAddress ? amountA : amountB).numerator.toString(),
          (steerToken0Address.toLowerCase() === baseCurrencyAddress ? amountB : amountA).numerator.toString(),
          0,
          0,
          account,
          { gasLimit: calculateGasMargin(estimatedGas) }
        )
        setAttemptingTxn(false)
        addTransaction(response, {
          type: TransactionType.ADD_LIQUIDITY_STEER_POOL,
          baseCurrencyId: currencyId(baseCurrency),
          quoteCurrencyId: currencyId(quoteCurrency),
          feeAmount: feeAmount!,
          expectedAmountBaseRaw: parsedAmounts[Field.CURRENCY_A]?.quotient?.toString() ?? '0',
          expectedAmountQuoteRaw: parsedAmounts[Field.CURRENCY_B]?.quotient?.toString() ?? '0',
        })
        setTxHash(response.hash)
      } catch (error) {
        console.error('Failed to send transaction', error)
        setAttemptingTxn(false)
      }
    } else {
      if (!positionManager) return

      if (position && account && deadline) {
        const useNative = baseCurrency.isNative ? baseCurrency : quoteCurrency.isNative ? quoteCurrency : undefined
        const { calldata, value } =
          hasExistingPosition && tokenId
            ? NonfungiblePositionManager.addCallParameters(position, {
                tokenId,
                slippageTolerance: allowedSlippage,
                deadline: deadline.toString(),
                useNative,
              })
            : NonfungiblePositionManager.addCallParameters(position, {
                slippageTolerance: allowedSlippage,
                recipient: account,
                deadline: deadline.toString(),
                useNative,
                createPool: noLiquidity,
              })

        const txn: { to: string; data: string; value: string } = {
          to: NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[chainId],
          data: calldata,
          value,
        }

        setAttemptingTxn(true)

        const eventData = {
          type: 'AddLiquidity',
          version: 3,
          account,
          wallet: connection.getName(),
          tokenA: baseCurrency.symbol,
          tokenAAmt: +formattedAmounts[Field.CURRENCY_A],
          tokenAAmtUsdc: Number(usdcValues[Field.CURRENCY_A]?.toFixed(6)),
          tokenB: quoteCurrency.symbol,
          tokenBAmt: +formattedAmounts[Field.CURRENCY_B],
          tokenBAmtUsdc: Number(usdcValues[Field.CURRENCY_B]?.toFixed(6)),
          poolToken: [currencies[Field.CURRENCY_A]?.symbol, currencies[Field.CURRENCY_B]?.symbol].join('/'),
          isCreate: Boolean(noLiquidity),
        }

        trackEvent(TRANSACTION_INITIATED, { ...eventData })

        provider
          .getSigner()
          .estimateGas(txn)
          .then((estimate) => {
            const newTxn = {
              ...txn,
              gasLimit: calculateGasMargin(estimate),
            }

            return provider
              .getSigner()
              .sendTransaction(newTxn)
              .then((response: TransactionResponse) => {
                setAttemptingTxn(false)
                addTransaction(response, {
                  type: TransactionType.ADD_LIQUIDITY_V3_POOL,
                  baseCurrencyId: currencyId(baseCurrency),
                  quoteCurrencyId: currencyId(quoteCurrency),
                  createPool: Boolean(noLiquidity),
                  expectedAmountBaseRaw: parsedAmounts[Field.CURRENCY_A]?.quotient?.toString() ?? '0',
                  expectedAmountQuoteRaw: parsedAmounts[Field.CURRENCY_B]?.quotient?.toString() ?? '0',
                  feeAmount: position.pool.fee,
                })
                trackEvent(TRANSACTION_SUCCESS, {
                  ...eventData,
                  txHash: response.hash,
                })
                setTxHash(response.hash)
                sendEvent({
                  category: 'Liquidity',
                  action: 'Add',
                  label: [currencies[Field.CURRENCY_A]?.symbol, currencies[Field.CURRENCY_B]?.symbol].join('/'),
                })
              })
          })
          .catch((error) => {
            console.error('Failed to send transaction', error)
            trackEvent(TRANSACTION_FAILED, { ...eventData, reason: error?.message || 'N/A' })
            setAttemptingTxn(false)
            // we only care if the error is something _other_ than the user rejected the tx
            if (error?.code !== 4001) {
              console.error(error)
            }
          })
      } else {
        return
      }
    }
  }

  const handleCurrencySelect = useCallback(
    (currencyNew: Currency, currencyIdOther?: string): (string | undefined)[] => {
      const currencyIdNew = currencyId(currencyNew)

      if (currencyIdNew === currencyIdOther) {
        // not ideal, but for now clobber the other if the currency ids are equal
        return [currencyIdNew, undefined]
      } else {
        // prevent weth + eth
        const isETHOrWETHNew =
          currencyIdNew === 'ETH' ||
          (chainId !== undefined && currencyIdNew === WRAPPED_NATIVE_CURRENCY[chainId]?.address)
        const isETHOrWETHOther =
          currencyIdOther !== undefined &&
          (currencyIdOther === 'ETH' ||
            (chainId !== undefined && currencyIdOther === WRAPPED_NATIVE_CURRENCY[chainId]?.address))

        if (isETHOrWETHNew && isETHOrWETHOther) {
          return [currencyIdNew, undefined]
        } else {
          return [currencyIdNew, currencyIdOther]
        }
      }
    },
    [chainId]
  )

  const handleCurrencyASelect = useCallback(
    (currencyANew: Currency) => {
      const [idA, idB] = handleCurrencySelect(currencyANew, currencyIdB)
      if (idB === undefined) {
        navigate(`/add/${idA}`)
      } else {
        navigate(`/add/${idA}/${idB}`)
      }
    },
    [handleCurrencySelect, currencyIdB, navigate]
  )

  const handleCurrencyBSelect = useCallback(
    (currencyBNew: Currency) => {
      const [idB, idA] = handleCurrencySelect(currencyBNew, currencyIdA)
      if (idA === undefined) {
        navigate(`/add/${idB}`)
      } else {
        navigate(`/add/${idA}/${idB}`)
      }
    },
    [handleCurrencySelect, currencyIdA, navigate]
  )

  const handleFeePoolSelect = useCallback(
    (newFeeAmount: FeeAmount) => {
      onLeftRangeInput('')
      onRightRangeInput('')
      navigate(`/add/${currencyIdA}/${currencyIdB}/${newFeeAmount}`)
    },
    [currencyIdA, currencyIdB, navigate, onLeftRangeInput, onRightRangeInput]
  )

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onFieldAInput('')
      // dont jump to pool page if creating
      navigate('/pools')
    }
    setTxHash('')
  }, [navigate, onFieldAInput, txHash])

  const addIsUnsupported = useIsSwapUnsupported(currencies?.CURRENCY_A, currencies?.CURRENCY_B)

  const clearAll = useCallback(() => {
    onFieldAInput('')
    onFieldBInput('')
    onLeftRangeInput('')
    onRightRangeInput('')
    navigate(`/add`)
  }, [navigate, onFieldAInput, onFieldBInput, onLeftRangeInput, onRightRangeInput])

  // get value and prices at ticks
  const { [Bound.LOWER]: tickLower, [Bound.UPPER]: tickUpper } = ticks
  const { [Bound.LOWER]: priceLower, [Bound.UPPER]: priceUpper } = pricesAtTicks

  const { getDecrementLower, getIncrementLower, getDecrementUpper, getIncrementUpper, getSetFullRange } =
    useRangeHopCallbacks(baseCurrency ?? undefined, quoteCurrency ?? undefined, feeAmount, tickLower, tickUpper, pool)

  // we need an existence check on parsed amounts for single-asset deposits
  const showApprovalA = approvalA !== ApprovalState.APPROVED && !!parsedAmounts[Field.CURRENCY_A]
  const showApprovalB = approvalB !== ApprovalState.APPROVED && !!parsedAmounts[Field.CURRENCY_B]

  const pendingText = `Supplying ${!depositADisabled ? parsedAmounts[Field.CURRENCY_A]?.toSignificant(6) : ''} ${
    !depositADisabled ? currencies[Field.CURRENCY_A]?.symbol : ''
  } ${!outOfRange ? 'and' : ''} ${!depositBDisabled ? parsedAmounts[Field.CURRENCY_B]?.toSignificant(6) : ''} ${
    !depositBDisabled ? currencies[Field.CURRENCY_B]?.symbol : ''
  }`

  const [searchParams, setSearchParams] = useSearchParams()

  const handleSetFullRange = useCallback(() => {
    getSetFullRange()

    const minPrice = pricesAtLimit[Bound.LOWER]
    if (minPrice) searchParams.set('minPrice', minPrice.toSignificant(5))
    const maxPrice = pricesAtLimit[Bound.UPPER]
    if (maxPrice) searchParams.set('maxPrice', maxPrice.toSignificant(5))
    setSearchParams(searchParams)

    sendEvent({
      category: 'Liquidity',
      action: 'Full Range Clicked',
    })
  }, [getSetFullRange, pricesAtLimit, searchParams, setSearchParams])

  // START: sync values with query string
  const oldSearchParams = usePrevious(searchParams)
  // use query string as an input to onInput handlers
  useEffect(() => {
    const minPrice = searchParams.get('minPrice')
    const oldMinPrice = oldSearchParams?.get('minPrice')
    if (minPrice && liquidityRangeType !== LiquidityManagement.MANUAL) {
      setSearchParams('minPrice', undefined)
    } else if (
      minPrice &&
      typeof minPrice === 'string' &&
      !isNaN(minPrice as any) &&
      (!oldMinPrice || oldMinPrice !== minPrice)
    ) {
      onLeftRangeInput(minPrice)
    }
    // disable eslint rule because this hook only cares about the url->input state data flow
    // input state -> url updates are handled in the input handlers
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, liquidityRangeType])
  useEffect(() => {
    const maxPrice = searchParams.get('maxPrice')
    const oldMaxPrice = oldSearchParams?.get('maxPrice')
    if (maxPrice && liquidityRangeType !== LiquidityManagement.MANUAL) {
      setSearchParams('maxPrice', undefined)
    } else if (
      maxPrice &&
      typeof maxPrice === 'string' &&
      !isNaN(maxPrice as any) &&
      (!oldMaxPrice || oldMaxPrice !== maxPrice)
    ) {
      onRightRangeInput(maxPrice)
    }
    // disable eslint rule because this hook only cares about the url->input state data flow
    // input state -> url updates are handled in the input handlers
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, liquidityRangeType])
  // END: sync values with query string

  const Buttons = () =>
    addIsUnsupported ? (
      <ButtonPrimary disabled={true} $borderRadius="12px" padding="12px">
        <ThemedText.DeprecatedMain mb="4px">
          <Trans>Unsupported Asset</Trans>
        </ThemedText.DeprecatedMain>
      </ButtonPrimary>
    ) : !account ? (
      <TraceEvent
        events={[BrowserEvent.onClick]}
        name={InterfaceEventName.CONNECT_WALLET_BUTTON_CLICKED}
        properties={{ received_swap_quote: false }}
        element={InterfaceElementName.CONNECT_WALLET_BUTTON}
      >
        <ButtonLight onClick={toggleWalletDrawer} $borderRadius="12px" padding="12px">
          <Trans>Connect Wallet</Trans>
        </ButtonLight>
      </TraceEvent>
    ) : (
      <AutoColumn gap="md">
        {(approvalA === ApprovalState.NOT_APPROVED ||
          approvalA === ApprovalState.PENDING ||
          approvalB === ApprovalState.NOT_APPROVED ||
          approvalB === ApprovalState.PENDING) &&
          isValid && (
            <RowBetween>
              {showApprovalA && (
                <ButtonPrimary
                  onClick={approveACallback}
                  disabled={approvalA === ApprovalState.PENDING}
                  width={showApprovalB ? '48%' : '100%'}
                >
                  {approvalA === ApprovalState.PENDING ? (
                    <Dots>
                      <Trans>Approving {currencies[Field.CURRENCY_A]?.wrapped?.symbol}</Trans>
                    </Dots>
                  ) : (
                    <Trans>Approve {currencies[Field.CURRENCY_A]?.wrapped?.symbol}</Trans>
                  )}
                </ButtonPrimary>
              )}
              {showApprovalB && (
                <ButtonPrimary
                  onClick={approveBCallback}
                  disabled={approvalB === ApprovalState.PENDING}
                  width={showApprovalA ? '48%' : '100%'}
                >
                  {approvalB === ApprovalState.PENDING ? (
                    <Dots>
                      <Trans>Approving {currencies[Field.CURRENCY_B]?.symbol}</Trans>
                    </Dots>
                  ) : (
                    <Trans>Approve {currencies[Field.CURRENCY_B]?.symbol}</Trans>
                  )}
                </ButtonPrimary>
              )}
            </RowBetween>
          )}
        <ButtonError
          onClick={
            amountToWrap
              ? onWrap
              : () => {
                  expertMode ? onAdd() : setShowConfirm(true)
                }
          }
          disabled={
            !isValid ||
            (approvalA !== ApprovalState.APPROVED && !depositADisabled) ||
            (approvalB !== ApprovalState.APPROVED && !depositBDisabled) ||
            (amountToWrap ? wrappingNative : false)
          }
          error={!isValid && !!parsedAmounts[Field.CURRENCY_A] && !!parsedAmounts[Field.CURRENCY_B]}
        >
          <Text fontWeight={500}>
            {errorMessage ? (
              errorMessage
            ) : amountToWrap && chainId ? (
              wrappingNative ? (
                <Trans>Wrapping {nativeOnChain(chainId).symbol}</Trans>
              ) : (
                <Trans>Wrap {nativeOnChain(chainId).symbol}</Trans>
              )
            ) : (
              <Trans>Preview</Trans>
            )}
          </Text>
        </ButtonError>
      </AutoColumn>
    )

  const usdcValueCurrencyA = usdcValues[Field.CURRENCY_A]
  const usdcValueCurrencyB = usdcValues[Field.CURRENCY_B]
  const currencyAFiat = useMemo(
    () => ({
      data: usdcValueCurrencyA ? parseFloat(usdcValueCurrencyA.toSignificant()) : undefined,
      isLoading: false,
    }),
    [usdcValueCurrencyA]
  )
  const currencyBFiat = useMemo(
    () => ({
      data: usdcValueCurrencyB ? parseFloat(usdcValueCurrencyB.toSignificant()) : undefined,
      isLoading: false,
    }),
    [usdcValueCurrencyB]
  )

  const owner = useSingleCallResult(tokenId ? positionManager : null, 'ownerOf', [tokenId]).result?.[0]
  const ownsNFT =
    addressesAreEquivalent(owner, account) || addressesAreEquivalent(existingPositionDetails?.operator, account)
  const showOwnershipWarning = Boolean(hasExistingPosition && account && !ownsNFT)

  const chainIdMemo = useMemo(() => chainId, [chainId])
  const steerVaults = useSteerVaults()[chainIdMemo || 0]
  const steerVaultsForPair = useMemo(
    () =>
      steerVaults?.filter((item) => {
        return (
          item.state !== SteerVaultState.Paused &&
          item.state !== SteerVaultState.Retired &&
          item.feeTier &&
          Number(item.feeTier) === feeAmount &&
          (item.tvl ?? 0) > 50 &&
          ((item.token0Address &&
            item.token1Address &&
            item.token0Address.toLowerCase() === baseCurrency?.wrapped.address.toLowerCase() &&
            item.token1Address.toLowerCase() === quoteCurrency?.wrapped.address.toLowerCase()) ||
            (item.token0Address &&
              item.token1Address &&
              item.token0Address.toLowerCase() === quoteCurrency?.wrapped.address.toLowerCase() &&
              item.token1Address.toLowerCase() === baseCurrency?.wrapped.address.toLowerCase()))
        )
      }) ?? [],
    [baseCurrency?.wrapped.address, feeAmount, quoteCurrency?.wrapped.address, steerVaults]
  )
  const steerVaultExists = steerVaultsForPair?.length

  useEffect(() => {
    if (hasExistingSteerPosition || (!hasExistingPosition && steerVaultExists)) {
      onChangeLiquidityRangeType(LiquidityManagement.STEER)
    } else {
      onChangeLiquidityRangeType(LiquidityManagement.MANUAL)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasExistingSteerPosition,
    hasExistingPosition,
    baseCurrency?.isNative,
    baseCurrency?.wrapped.address,
    currencyB?.isNative,
    currencyB?.wrapped.address,
    steerVaultExists,
  ])

  const automaticAvailable = steerVaultExists
  const isAutomatic = liquidityRangeType !== LiquidityManagement.MANUAL

  const handleAutomaticToggle = useCallback(() => {
    if (automaticAvailable) {
      onChangeLiquidityRangeType(isAutomatic ? LiquidityManagement.MANUAL : LiquidityManagement.STEER)
    }
  }, [automaticAvailable, isAutomatic, onChangeLiquidityRangeType])

  const handlePresetRangeSelection = useCallback(
    (preset: IPresetArgs) => {
      if (!price || liquidityRangeType === LiquidityManagement.MANUAL) return
      onChangePresetRange(preset)
      onLeftRangeInput(
        tickToPrice(
          baseCurrency!.wrapped,
          quoteCurrency!.wrapped,
          invertPrice ? preset.upperTick : preset.lowerTick
        ).toSignificant(6)
      )
      onRightRangeInput(
        tickToPrice(
          baseCurrency!.wrapped,
          quoteCurrency!.wrapped,
          invertPrice ? preset.lowerTick : preset.upperTick
        ).toSignificant(6)
      )
    },
    [
      price,
      liquidityRangeType,
      onChangePresetRange,
      onLeftRangeInput,
      baseCurrency,
      quoteCurrency,
      invertPrice,
      onRightRangeInput,
    ]
  )

  const steerPreset = useMemo(
    () =>
      hasExistingSteerPosition
        ? {
            liquidityManagement: LiquidityManagement.STEER,
            lowerTick: Number(existingSteerPosition.lowerTick),
            upperTick: Number(existingSteerPosition.upperTick),
            address: existingSteerPosition.address,
            tokenStr: existingSteerPosition.token0Address + '-' + existingSteerPosition.token1Address,
          }
        : undefined,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasExistingSteerPosition]
  )

  const steerPositionToAdd = useMemo(
    () =>
      hasExistingSteerPosition && amountA && amountB
        ? {
            ...existingSteerPosition,
            token0BalanceWallet: (invertPrice ? amountB : amountA) as CurrencyAmount<Token>,
            token1BalanceWallet: (invertPrice ? amountA : amountB) as CurrencyAmount<Token>,
          }
        : undefined,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasExistingSteerPosition, amountA, amountB, invertPrice]
  )

  useEffect(() => {
    if (steerPreset && steerPreset?.address !== presetRange?.address) {
      handlePresetRangeSelection(steerPreset)
    }
  }, [handlePresetRangeSelection, steerPreset, presetRange])

  return (
    <>
      <ScrollablePage>
        <TransactionConfirmationModal
          isOpen={showConfirm}
          onDismiss={handleDismissConfirmation}
          attemptingTxn={attemptingTxn}
          hash={txHash}
          content={() => (
            <ConfirmationModalContent
              title={<Trans>Add Liquidity</Trans>}
              onDismiss={handleDismissConfirmation}
              topContent={() =>
                hasExistingSteerPosition && steerPositionToAdd ? (
                  <SteerPositionPreview position={steerPositionToAdd} />
                ) : (
                  <Review
                    parsedAmounts={parsedAmounts}
                    position={position}
                    existingPosition={existingPosition}
                    priceLower={priceLower}
                    priceUpper={priceUpper}
                    outOfRange={outOfRange}
                    ticksAtLimit={ticksAtLimit}
                    isAutomatic={isAutomatic}
                  />
                )
              }
              bottomContent={() => (
                <ButtonPrimary style={{ marginTop: '1rem' }} onClick={onAdd}>
                  <Text fontWeight={500} fontSize={20}>
                    <Trans>Add</Trans>
                  </Text>
                </ButtonPrimary>
              )}
            />
          )}
          pendingText={pendingText}
        />
        <PageWrapper wide={!hasExistingPosition && !hasExistingSteerPosition}>
          <AddRemoveTabs
            creating={false}
            adding={true}
            positionID={tokenId}
            steer={liquidityRangeType === LiquidityManagement.STEER}
            defaultSlippage={DEFAULT_ADD_IN_RANGE_SLIPPAGE_TOLERANCE}
            showBackLink={!hasExistingPosition && !hasExistingSteerPosition}
          >
            {!hasExistingPosition && !hasExistingSteerPosition && (
              <Row justifyContent="flex-end" style={{ width: 'fit-content', minWidth: 'fit-content' }}>
                <MediumOnly>
                  <ButtonText onClick={clearAll} margin="0 15px 0 0">
                    <ThemedText.DeprecatedBlue fontSize="12px">
                      <Trans>Clear All</Trans>
                    </ThemedText.DeprecatedBlue>
                  </ButtonText>
                </MediumOnly>
                {baseCurrency && quoteCurrency ? (
                  <RateToggle
                    currencyA={baseCurrency}
                    currencyB={quoteCurrency}
                    handleRateToggle={() => {
                      if (!ticksAtLimit[Bound.LOWER] && !ticksAtLimit[Bound.UPPER]) {
                        onLeftRangeInput((invertPrice ? priceLower : priceUpper?.invert())?.toSignificant(6) ?? '')
                        onRightRangeInput((invertPrice ? priceUpper : priceLower?.invert())?.toSignificant(6) ?? '')
                        onFieldAInput(formattedAmounts[Field.CURRENCY_B] ?? '')
                      }
                      navigate(
                        `/add/${currencyIdB as string}/${currencyIdA as string}${feeAmount ? '/' + feeAmount : ''}`
                      )
                    }}
                  />
                ) : null}
              </Row>
            )}
          </AddRemoveTabs>
          <Wrapper>
            <ResponsiveTwoColumns wide={!hasExistingPosition && !hasExistingSteerPosition}>
              <AutoColumn gap="lg">
                {!hasExistingPosition && !hasExistingSteerPosition && (
                  <>
                    <AutoColumn gap="md">
                      <RowBetween paddingBottom="20px">
                        <ThemedText.DeprecatedLabel>
                          <Trans>Select Pair</Trans>
                        </ThemedText.DeprecatedLabel>
                      </RowBetween>
                      <RowBetween>
                        <CurrencyDropdown
                          value={formattedAmounts[Field.CURRENCY_A]}
                          onUserInput={onFieldAInput}
                          hideInput={true}
                          onMax={() => {
                            onFieldAInput(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '')
                          }}
                          onCurrencySelect={handleCurrencyASelect}
                          showMaxButton={!atMaxAmounts[Field.CURRENCY_A]}
                          currency={currencies[Field.CURRENCY_A] ?? null}
                          id="add-liquidity-input-tokena"
                          showCommonBases
                        />

                        <div style={{ width: '12px' }} />

                        <CurrencyDropdown
                          value={formattedAmounts[Field.CURRENCY_B]}
                          hideInput={true}
                          onUserInput={onFieldBInput}
                          onCurrencySelect={handleCurrencyBSelect}
                          onMax={() => {
                            onFieldBInput(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '')
                          }}
                          showMaxButton={!atMaxAmounts[Field.CURRENCY_B]}
                          currency={currencies[Field.CURRENCY_B] ?? null}
                          id="add-liquidity-input-tokenb"
                          showCommonBases
                        />
                      </RowBetween>

                      <FeeSelector
                        disabled={!quoteCurrency || !baseCurrency}
                        feeAmount={feeAmount}
                        handleFeePoolSelect={handleFeePoolSelect}
                        currencyA={baseCurrency ?? undefined}
                        currencyB={quoteCurrency ?? undefined}
                      />
                    </AutoColumn>{' '}
                  </>
                )}
                {hasExistingPosition && existingPosition && (
                  <PositionPreview
                    position={existingPosition}
                    title={<Trans>Selected Range</Trans>}
                    inRange={!outOfRange}
                    ticksAtLimit={ticksAtLimit}
                    parsedAmounts={parsedAmounts}
                    isAutomatic={isAutomatic}
                  />
                )}
                {hasExistingSteerPosition && existingSteerPosition && (
                  <SteerPositionPreview position={existingSteerPosition} />
                )}
              </AutoColumn>
              <div>
                <DynamicSection
                  disabled={tickLower === undefined || tickUpper === undefined || invalidPool || invalidRange}
                >
                  <AutoColumn gap="md">
                    <ThemedText.DeprecatedLabel>
                      {hasExistingPosition || hasExistingSteerPosition ? (
                        <Trans>Add more liquidity</Trans>
                      ) : (
                        <Trans>Deposit Amounts</Trans>
                      )}
                    </ThemedText.DeprecatedLabel>

                    <CurrencyInputPanel
                      value={formattedAmounts[Field.CURRENCY_A]}
                      onUserInput={onFieldAInput}
                      onMax={() => {
                        onFieldAInput(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '')
                      }}
                      showMaxButton={!atMaxAmounts[Field.CURRENCY_A]}
                      currency={currencies[Field.CURRENCY_A] ?? null}
                      id="add-liquidity-input-tokena"
                      fiatValue={currencyAFiat}
                      showCommonBases
                      locked={depositADisabled}
                      withWNative={isAutomatic && currencies[Field.CURRENCY_A]?.isNative}
                    />

                    <CurrencyInputPanel
                      value={formattedAmounts[Field.CURRENCY_B]}
                      onUserInput={onFieldBInput}
                      onMax={() => {
                        onFieldBInput(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '')
                      }}
                      showMaxButton={!atMaxAmounts[Field.CURRENCY_B]}
                      fiatValue={currencyBFiat}
                      currency={currencies[Field.CURRENCY_B] ?? null}
                      id="add-liquidity-input-tokenb"
                      showCommonBases
                      locked={depositBDisabled}
                      withWNative={isAutomatic && currencies[Field.CURRENCY_B]?.isNative}
                    />
                  </AutoColumn>
                </DynamicSection>
              </div>

              {!hasExistingPosition && !hasExistingSteerPosition ? (
                <>
                  <HideMedium>
                    <Buttons />
                  </HideMedium>
                  <RightContainer gap="lg">
                    <DynamicSection gap="md" disabled={!feeAmount || invalidPool}>
                      {!noLiquidity ? (
                        <>
                          <RowBetween>
                            <ThemedText.DeprecatedLabel>
                              <Trans>Set Price Range</Trans>
                            </ThemedText.DeprecatedLabel>
                          </RowBetween>

                          <div
                            style={{
                              width: '100%',
                              display: automaticAvailable ? 'flex' : 'none',
                              alignItems: 'center',
                            }}
                            onClick={handleAutomaticToggle}
                          >
                            <ToggleWrapper width="100%">
                              <ToggleElement isActive={isAutomatic} fontSize="18px">
                                <Trans>Automatic</Trans>
                              </ToggleElement>
                              <ToggleElement isActive={!isAutomatic} fontSize="18px">
                                <Trans>Manual</Trans>
                              </ToggleElement>
                            </ToggleWrapper>
                          </div>

                          {price && baseCurrency && quoteCurrency && !noLiquidity && (
                            <AutoRow gap="4px" justify="center" style={{ marginTop: '0.5rem' }}>
                              <Trans>
                                <ThemedText.DeprecatedMain
                                  fontWeight={500}
                                  textAlign="center"
                                  fontSize={12}
                                  color="text1"
                                >
                                  Current Price:
                                </ThemedText.DeprecatedMain>
                                <ThemedText.DeprecatedBody
                                  fontWeight={500}
                                  textAlign="center"
                                  fontSize={12}
                                  color="text1"
                                >
                                  <HoverInlineText
                                    maxCharacters={20}
                                    text={invertPrice ? price.invert().toSignificant(6) : price.toSignificant(6)}
                                  />
                                </ThemedText.DeprecatedBody>
                                <ThemedText.DeprecatedBody color="text2" fontSize={12}>
                                  {quoteCurrency?.symbol} per {baseCurrency.symbol}
                                </ThemedText.DeprecatedBody>
                              </Trans>
                            </AutoRow>
                          )}

                          <LiquidityChartRangeInput
                            currencyA={baseCurrency ?? undefined}
                            currencyB={quoteCurrency ?? undefined}
                            feeAmount={feeAmount}
                            ticksAtLimit={ticksAtLimit}
                            price={
                              price ? parseFloat((invertPrice ? price.invert() : price).toSignificant(8)) : undefined
                            }
                            priceLower={priceLower}
                            priceUpper={priceUpper}
                            onLeftRangeInput={onLeftRangeInput}
                            onRightRangeInput={onRightRangeInput}
                            interactive={!hasExistingPosition && !hasExistingSteerPosition && !isAutomatic}
                            automatic={Boolean(automaticAvailable)}
                          />
                          <PresetSelector
                            preset={presetRange}
                            handlePresetRangeSelection={handlePresetRangeSelection}
                            price={price}
                            liquidityManagement={liquidityRangeType}
                            steerPairs={steerVaultsForPair}
                            disabled={!isAutomatic}
                          />
                        </>
                      ) : (
                        <AutoColumn gap="md">
                          <RowBetween>
                            <ThemedText.DeprecatedLabel>
                              <Trans>Set Starting Price</Trans>
                            </ThemedText.DeprecatedLabel>
                          </RowBetween>
                          {noLiquidity && (
                            <BlueCard
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                padding: '1rem 1rem',
                              }}
                            >
                              <ThemedText.DeprecatedBody
                                fontSize={14}
                                style={{ fontWeight: 500 }}
                                textAlign="left"
                                color={theme.accentAction}
                              >
                                <Trans>
                                  This pool must be initialized before you can add liquidity. To initialize, select a
                                  starting price for the pool. Then, enter your liquidity price range and deposit
                                  amount. Gas fees will be higher than usual due to the initialization transaction.
                                </Trans>
                              </ThemedText.DeprecatedBody>
                            </BlueCard>
                          )}
                          <OutlineCard padding="12px">
                            <StyledInput
                              className="start-price-input"
                              value={startPriceTypedValue}
                              onUserInput={onStartPriceInput}
                            />
                          </OutlineCard>
                          <RowBetween
                            style={{ backgroundColor: theme.deprecated_bg1, padding: '12px', borderRadius: '12px' }}
                          >
                            <ThemedText.DeprecatedMain>
                              <Trans>Current {baseCurrency?.symbol} Price:</Trans>
                            </ThemedText.DeprecatedMain>
                            <ThemedText.DeprecatedMain>
                              {price ? (
                                <ThemedText.DeprecatedMain>
                                  <RowFixed>
                                    <HoverInlineText
                                      maxCharacters={20}
                                      text={invertPrice ? price?.invert()?.toSignificant(5) : price?.toSignificant(5)}
                                    />{' '}
                                    <span style={{ marginLeft: '4px' }}>{quoteCurrency?.symbol}</span>
                                  </RowFixed>
                                </ThemedText.DeprecatedMain>
                              ) : (
                                '-'
                              )}
                            </ThemedText.DeprecatedMain>
                          </RowBetween>
                        </AutoColumn>
                      )}
                    </DynamicSection>

                    <DynamicSection
                      gap="md"
                      disabled={!feeAmount || invalidPool || (noLiquidity && !startPriceTypedValue)}
                    >
                      <StackedContainer>
                        <StackedItem>
                          <AutoColumn gap="md">
                            {noLiquidity && (
                              <RowBetween>
                                <ThemedText.DeprecatedLabel>
                                  <Trans>Set Price Range</Trans>
                                </ThemedText.DeprecatedLabel>
                              </RowBetween>
                            )}
                            {!isAutomatic ? (
                              <>
                                <RangeSelector
                                  priceLower={priceLower}
                                  priceUpper={priceUpper}
                                  getDecrementLower={getDecrementLower}
                                  getIncrementLower={getIncrementLower}
                                  getDecrementUpper={getDecrementUpper}
                                  getIncrementUpper={getIncrementUpper}
                                  onLeftRangeInput={onLeftRangeInput}
                                  onRightRangeInput={onRightRangeInput}
                                  currencyA={baseCurrency}
                                  currencyB={quoteCurrency}
                                  feeAmount={feeAmount}
                                  ticksAtLimit={ticksAtLimit}
                                />
                                {!noLiquidity && <PresetsButtons onSetFullRange={handleSetFullRange} />}
                              </>
                            ) : (
                              <></>
                            )}
                          </AutoColumn>
                        </StackedItem>
                      </StackedContainer>

                      {outOfRange ? (
                        <YellowCard padding="8px 12px" $borderRadius="12px">
                          <RowBetween>
                            <AlertTriangle stroke={theme.deprecated_yellow3} size="16px" />
                            <ThemedText.DeprecatedYellow ml="12px" fontSize="12px">
                              <Trans>
                                Your position will not earn fees or be used in trades until the market price moves into
                                your range.
                              </Trans>
                            </ThemedText.DeprecatedYellow>
                          </RowBetween>
                        </YellowCard>
                      ) : null}

                      {invalidRange ? (
                        <YellowCard padding="8px 12px" $borderRadius="12px">
                          <RowBetween>
                            <AlertTriangle stroke={theme.deprecated_yellow3} size="16px" />
                            <ThemedText.DeprecatedYellow ml="12px" fontSize="12px">
                              <Trans>Invalid range selected. The min price must be lower than the max price.</Trans>
                            </ThemedText.DeprecatedYellow>
                          </RowBetween>
                        </YellowCard>
                      ) : null}
                    </DynamicSection>

                    <MediumOnly>
                      <Buttons />
                    </MediumOnly>
                  </RightContainer>
                </>
              ) : (
                <Buttons />
              )}
            </ResponsiveTwoColumns>
          </Wrapper>
        </PageWrapper>
        {showOwnershipWarning && <OwnershipWarning ownerAddress={owner} />}
        {addIsUnsupported && (
          <UnsupportedCurrencyFooter
            show={addIsUnsupported}
            currencies={[currencies.CURRENCY_A, currencies.CURRENCY_B]}
          />
        )}
      </ScrollablePage>
      <SwitchLocaleLink />
    </>
  )
}
