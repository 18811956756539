import { StakingPool, SteerVault } from 'hooks/useSteerData'
import { useMemo } from 'react'
import { useAppSelector } from 'state/hooks'

import { AppState } from '../types'

export function useV3Tvl(): AppState['info']['v3Tvl'] {
  return useAppSelector((state: AppState) => state.info.v3Tvl)
}

export function useV2Tvl(): AppState['info']['v2Tvl'] {
  return useAppSelector((state: AppState) => state.info.v2Tvl)
}

export function useV3TxVolume(): AppState['info']['v3TxVolume'] {
  return useAppSelector((state: AppState) => state.info.v3TxVolume)
}

export function useV2TxVolume(): AppState['info']['v2TxVolume'] {
  return useAppSelector((state: AppState) => state.info.v2TxVolume)
}

export function useSteerVaults(): AppState['info']['steerVaults'] {
  return useAppSelector((state: AppState) => state.info.steerVaults)
}

export function useSteerVaultsCached(chainId?: number): SteerVault[] {
  const vaults = useAppSelector((state: AppState) => state.info.steerVaults[chainId ?? 0])
  return useMemo(() => vaults ?? [], [vaults])
}

export function useSteerStakingPools(chainId?: number): StakingPool[] {
  const stakingPools = useAppSelector((state: AppState) => state.info.steerStakingPools[chainId ?? 0])
  return useMemo(() => stakingPools ?? [], [stakingPools])
}

export function useStakingApr(chainId?: number, vaultAddress?: string): number | null {
  return useAppSelector((state: AppState) => state.info.stakingApr[chainId ?? 0]?.[vaultAddress ?? ''])
}
