import { useQuery } from '@apollo/client'
import { isSquidChain, SupportedChainId } from 'constants/chains'
import {
  v2Client,
  v2TvlQuery,
  v2TxVolumes,
  v3Client,
  v3SubgraphTxVolumes,
  v3TvlQuery,
  v3TvlSubgraphQuery,
  v3TxVolumes,
} from 'graphql/arth/apollo'
import { useFetchSteerStakingPool, useSteerVaults } from 'hooks/useSteerData'
import ms from 'ms.macro'
import { useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { AppState } from 'state/types'

import { setSteerStakingPools, setSteerVaults, setV2Tvl, setV2TxVolume, setV3Tvl, setV3TxVolume } from './reducer'

// Custom hook to handle errors and refetch data
function useErrorHandler(error: any, refetch: () => void) {
  useEffect(() => {
    if (error) {
      console.debug(error)
      setTimeout(refetch, ms`10s`)
    }
  }, [error, refetch])
}

export default function Updater(): null {
  const dispatch = useAppDispatch()
  const _chainId = useAppSelector((state: AppState) => state.application.chainId)
  const chainId = useMemo(() => _chainId ?? SupportedChainId.ASTR, [_chainId])

  const date = useMemo(() => {
    const date = new Date()
    date.setDate(date.getDate() - 1)
    date.setHours(date.getHours() - 1)
    return date
  }, [])

  const v3ApolloClient = useMemo(() => v3Client[chainId], [chainId])

  const isSquid = useMemo(() => isSquidChain(chainId), [chainId])

  const { data: vaults } = useSteerVaults(chainId)
  const { data: stakingPools } = useFetchSteerStakingPool(chainId)

  const v2ApolloClient = useMemo(() => v2Client[chainId], [chainId])

  const {
    loading: v3Tvlloading,
    error: v3Tvlerror,
    data: v3Tvldata,
    refetch: v3Tvlrefetch,
  } = useQuery(isSquid ? v3TvlQuery : v3TvlSubgraphQuery, {
    client: v3ApolloClient,
    skip: !v3ApolloClient,
  })
  const v3Tvl = useMemo(() => {
    if (v3Tvlloading) return null
    if (v3Tvlerror) return null
    if (!v3Tvldata) return null

    const pools = v3Tvldata.pools
    return pools.reduce(
      (total: number, current: { totalValueLockedUSD: string }) => total + Number(current.totalValueLockedUSD),
      0
    )
  }, [v3Tvlloading, v3Tvlerror, v3Tvldata])

  const {
    loading: v2Tvlloading,
    error: v2Tvlerror,
    data: v2Tvldata,
    refetch: v2Tvlrefetch,
  } = useQuery(v2TvlQuery, {
    client: v2ApolloClient,
    skip: !v2ApolloClient,
  })
  const v2Tvl = useMemo(() => {
    if (v2Tvlloading) return null
    if (v2Tvlerror) return null
    if (!v2Tvldata) return null

    const pairs = v2Tvldata.pairs
    return pairs.reduce((total: number, current: { reserveUSD: string }) => total + Number(current.reserveUSD), 0)
  }, [v2Tvlloading, v2Tvlerror, v2Tvldata])

  const {
    loading: v3Volumesloading,
    error: v3Volumeserror,
    data: v3Volumesdata,
    refetch: v3Volumesrefetch,
  } = useQuery(isSquid ? v3TxVolumes : v3SubgraphTxVolumes, {
    client: v3ApolloClient,
    skip: !v3ApolloClient,
    variables: {
      date: isSquid ? date.toJSON() : Math.floor(date.getTime() / 1000),
    },
  })
  const v3Volumes = useMemo(() => {
    if (v3Volumesloading) return null
    if (v3Volumeserror) return null
    if (!v3Volumesdata) return null

    const poolHourData = isSquid ? v3Volumesdata.poolHourData : v3Volumesdata.poolHourDatas
    return poolHourData.reduce((total: number, current: { volumeUSD: string }) => total + Number(current.volumeUSD), 0)
  }, [v3Volumesloading, v3Volumeserror, v3Volumesdata, isSquid])

  const {
    loading: v2Volumesloading,
    error: v2Volumeserror,
    data: v2Volumesdata,
    refetch: v2Volumesrefetch,
  } = useQuery(v2TxVolumes, {
    client: v2ApolloClient,
    skip: !v2ApolloClient,
    variables: {
      timestamp: date.getTime(),
    },
  })
  const v2Volumes = useMemo(() => {
    if (v2Volumesloading) return null
    if (v2Volumeserror) return null
    if (!v2Volumesdata) return null

    const pairHourData = v2Volumesdata.pairHourData
    return pairHourData.reduce(
      (total: number, current: { hourlyVolumeUSD: string }) => total + Number(current.hourlyVolumeUSD),
      0
    )
  }, [v2Volumesloading, v2Volumeserror, v2Volumesdata])

  useErrorHandler(v3Tvlerror, v3Tvlrefetch)
  useErrorHandler(v2Tvlerror, v2Tvlrefetch)
  useErrorHandler(v3Volumeserror, v3Volumesrefetch)
  useErrorHandler(v2Volumeserror, v2Volumesrefetch)

  useEffect(() => {
    dispatch(setSteerVaults({ chainId, value: vaults }))
  }, [dispatch, vaults, chainId])

  useEffect(() => {
    dispatch(setSteerStakingPools({ chainId, value: stakingPools }))
  }, [dispatch, stakingPools, chainId])

  useEffect(() => {
    dispatch(setV3Tvl({ chainId, value: v3Tvl }))
  }, [dispatch, v3Tvl, chainId])

  useEffect(() => {
    dispatch(setV2Tvl({ chainId, value: v2Tvl }))
  }, [dispatch, v2Tvl, chainId])

  useEffect(() => {
    dispatch(setV3TxVolume({ chainId, value: v3Volumes }))
  }, [dispatch, v3Volumes, chainId])

  useEffect(() => {
    dispatch(setV2TxVolume({ chainId, value: v2Volumes }))
  }, [dispatch, v2Volumes, chainId])
  return null
}
